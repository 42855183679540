import {
    Card,
    CardHeader,
    CardBody,
} from "reactstrap";
import React from 'react';
import QRCode from 'react-qr-code';
import "../../assets/css/qr-card-styles.css";
import Logo from '../../assets/img/brand/nenasala-logo.png';
import Background from '../../assets/img/brand/nenasala-bg-1.png';

// const StudentIdBack = ({ student }) => {

//     return (
//         <Card
//             className="qRCardBack"
//             style={{
//                 position: 'relative',
//                 margin: 'auto',
//                 color: 'white',
//                 borderRadius: '20px',
//                 textAlign: 'center',
//                 padding: '20px',
//                 overflow: 'hidden',
//             }}
//         >
//             <div
//                 style={{
//                     position: 'absolute',
//                     top: 0,
//                     left: 0,
//                     width: '100%',
//                     height: '120px',
//                     // backgroundColor: '#021341',
//                     zIndex: 0,
//                 }}
//                 className="shape"
//             ></div>
//             <CardHeader
//                 className="idCardHeader"
//                 style={{ borderBottom: 'none', backgroundColor: 'transparent', position: 'relative', zIndex: 10 }}
//             >
//                 <img src={Logo} alt="Institute Logo" width={60} crossOrigin="anonymous" />
//             </CardHeader>
//             <CardBody className="qrBackBody" style={{ zIndex: 1, position: 'relative' }}>
// <div className="qrDiv">
//     <QRCode value={student.barcode} size={100} className="code" />
// </div>
//                 <p className="termsTitle">Terms & Conditions</p>
//                 <p className="terms">This card is non-transferable and the property of Nenasala, Balangoda.
//                     It is the cardholder's responsibility to protect and maintain the condition of his/her card.
//                 </p>
//             </CardBody>
//             <div className="instDetails">
//                 <p>Nenasala</p>
//                 <p>Balangoda</p>
//             </div>
//         </Card>
//     );
// }
// export default StudentIdBack;

const StudentIdBack = ({ student }) => {

    return (
        <div style={styles.card}>
            <div style={styles.header}>
                <img src={Logo} alt="Institute Logo" width={100} crossOrigin="anonymous" />
            </div>
            <div style={styles.studentInfo}>
                <h6 style={{ marginBottom: '0px' }}>STUDENT ID CARD</h6>
            </div>
            <div style={styles.qrCodeContainer}>
                <QRCode value={student.barcode} size={80} className="code" />
            </div>
            <div style={styles.infoText}>
                <p style={styles.infoText}>කාඩ්පත භාවිතයට අදාල උපදෙස්</p>
                <p style={styles.infoText}>1. පංතියට පැමිණීමේ දී කාඩ්පත රැගෙන ඒම අනිවාර්ය වේ.</p>
                <p style={styles.infoText}>2. කාඩ්පත අන්සතු කිරීම සපුරා තහනම්.</p>
                <p style={styles.infoText}>3. කාඩ්පත ප්‍රවේශම් කර ගැනීම ඔබ සතු වගකීමකි.</p>
                <p style={styles.infoText}>4. මුදල් ගෙවීමේදී සහ පංතියට පැමිණීමේ දී කාඩ්පත ඉදිරිපත් කළ යුතුය.</p>
                <p style={styles.infoText}>5. නැණසල මගින් සංවිධානය වන සෑම කටයුත්තකදීම කාඩ්පත භාවිතා කළ යුතුය.</p>
            </div>
        </div>
    );
};

const styles = {
    card: {
        width: '55mm',
        height: '87mm',
        backgroundColor: '#fff',
        padding: '10px 15px',
        borderRadius: '8px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        fontFamily: 'Arial, sans-serif',
        backgroundImage: `url(${Background})`,  // Add the background image URL here
        backgroundSize: 'cover',                // Adjust this to 'contain' if you prefer
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    header: {
        textAlign: 'center',
        marginBottom: '10px',
    },
    logo: {
        width: '50px',
        height: '50px',
    },
    instituteName: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    instituteDetails: {
        fontSize: '12px',
        color: '#555',
    },
    studentInfo: {
        textAlign: 'center',
        padding: '0px 10px'
    },
    photoPlaceholder: {
        width: '80px',
        height: '80px',
        borderRadius: '50%',
        backgroundColor: '#d9d9d9',
        margin: '10px auto',
    },
    barcode: {
        display: 'block',
        margin: '10px auto',
    },
    contactInfoSec: {
        textAlign: 'center',
        fontSize: '10px',
        color: '#333',
    },
    contactInfo: {
        textAlign: 'center',
        fontSize: '8px',
        color: '#333',
    },
    qrCodeContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '10px 0',
    },
    infoText: {
        fontSize: '6px',
        color: '#000000',
    }
};

export default StudentIdBack;