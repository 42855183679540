import {
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Table,
  Card,
  CardHeader,
  Modal,
  Button,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from "reactstrap";
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import Paginations from "components/Pagination/Paginations";
import { BiEdit } from "react-icons/bi";
import { Link, useParams } from "react-router-dom";
import { addNewExam } from "actions/Exams";
import { filterExams } from "actions/Exams";
import { editExam } from "actions/Exams";

const AdminExam = () => {

  const { teacherClassId, teacherId } = useParams();

  const [allExams, setAllExams] = useState(null);
  const [isExams, setIsExams] = useState(false);
  const [isLoadingExams, setLoadingExams] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPge, SetItemsPerPage] = useState(10);
  const [skip, setSkip] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentStart, setCurrentStart] = useState(0);


  const fetchData = async (itemsPerPge, skip) => {
    try {
      const tutes = await filterExams(teacherClassId, teacherId, skip, itemsPerPge);
      setPageCount(tutes.data.pageCount);
      setAllExams(tutes.data);
      setLoadingExams(false);
      setIsExams(true);
    } catch (error) {
      setLoadingExams(false);
      setIsExams(false);
    }
  };

  useEffect(() => {
    fetchData(itemsPerPge, skip);
  }, []);


  const handlePagination = async (pageNumber) => {
    await fetchData(itemsPerPge, pageNumber - 1);
    setCurrentPage(pageNumber);
  }

  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        {isLoadingExams ? (
          <Card>
            <Spinner className="m-10" color="primary" size="sm">Loading...</Spinner>
          </Card>
        ) : !isExams ? (
          <Card className="text-center" style={{ padding: "1rem" }}>
            <img src={require("../../assets/img/brand/nodata.png")} className="noDataImage" />
          </Card>
        ) : (
          <Card className="shadow" style={{ padding: "2rem" }}>
            <Row>
              <Col>
                <h3 className="mb-3">Exams</h3>
              </Col>
            </Row>
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Maximum Mark</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {allExams?.map((exam, index) => (
                  <tr key={index}>
                    <td>{exam.name || ''}</td>
                    <td>{exam.limit || ''}</td>
                    <td>{exam.status ? "Active" : "Inactive" || ''}</td>
                    <td>
                      <Button
                        color="default"
                        type="button"
                        tag={Link}
                        to={`/admin/teacher/exam-results/${teacherClassId}/${exam.id}/${exam.limit}`}
                      >
                        Resullts
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div style={{ paddingTop: "10px" }}>
              {pageCount > 1 ?
                <Paginations totalPages={pageCount} handlePagination={handlePagination} currentPage={currentPage}
                  currentStart={currentStart}
                  setCurrentStart={setCurrentStart}></Paginations>
                : null}
            </div>
          </Card>
        )}
      </div>
    </>
  );
};

export default AdminExam;
