import {
  Table,
  Card,
  CardHeader,
  Modal,
  Button,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Spinner,
  InputGroup,
  Label,
} from "reactstrap";
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import { editSupplier } from "actions/Suppliers";
import { Link } from "react-router-dom";
import { BiEdit } from "react-icons/bi";

import "../../assets/css/qr-card-styles.css";
import "../../assets/css/styles.css";
import Paginations from "components/Pagination/Paginations";
import { getSuppliersByLimit } from "actions/Suppliers";
import { getSubjectsBySearch } from "actions/Subjects";
import { addNewSupplier } from "actions/Suppliers";

const Suppliers = () => {
  const Status = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
  };

  const [allSuppliers, setAllSuppliers] = useState(null);
  const [isAllSuppliers, setIsAllSuppliers] = useState(false);

  const [isSuccess, setSuccess] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [editModal, setEditmodal] = useState(false);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [isInsertButtonDisabled, setinsertButtonDisabled] = useState(false);
  const [isSuccessMessage, setSuccessMessage] = useState("");
  const [isErrorMessage, setErrorMessage] = useState("");
  const [isLoadingSuppliers, setLoadingSuppliers] = useState(true);
  const [isEditedInput, setEditedInput] = useState(false);
  const [isDisabledEditButton, setDisabledEditButton] = useState(true);
  const [selectedGradeForSupplier, setSelectedGradeForSupplier] = useState(null);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPge, SetItemsPerPage] = useState(10);
  const [skip, setSkip] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentStart, setCurrentStart] = useState(0);
  const [selectedGradeFilter, setSelectedGradeFilter] = useState(null);
  const [selectedSubjectFilter, setSelectedSubjectFilter] = useState(null);
  const [selectedTeacherFilter, setSelectedTeacherFilter] = useState(null);
  const [filterSupplier, setFilterSupplier] = useState(false);
  const [birthDate, setBirthDate] = useState(new Date());
  const [selectedSex, setSelectedSex] = useState(null);
  const [selectedSyllabus, setSelectedSyllabus] = useState(null);
  const [selectedMedium, setSelectedMedium] = useState(null);
  const [selectedMode, setSelectedMode] = useState(null);
  const [selectedEditedSex, setEditedSelectedSex] = useState(null);
  const [selectedEditedSyllabus, setEditedSelectedSyllabus] = useState(null);
  const [selectedEditedMedium, setEditedSelectedMedium] = useState(null);
  const [selectedEditedMode, setEditedSelectedMode] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [initialSupplier, setInitialSupplier] = useState({
    id: null,
    name: "",
    phone_number: "",
    address: "",
  });

  const [isData, setIsData] = useState({
    name: "",
    phone_number: "",
    address: "",
  });

  const [isError, setIsError] = useState({
    name: "",
    phone_number: "",
    address: "",
  });

  const [isEditError, setEditError] = useState({
    name: "",
    phone_number: "",
    address: "",
  });

  const getSuppliers = async (itemsPerPge, skip) => {
    const suppliers = await getSuppliersByLimit(itemsPerPge, skip);
    setPageCount(suppliers.data.pageCount);
    setAllSuppliers(suppliers.data.data);
    if (suppliers.data.data.length > 0) {
      setIsAllSuppliers(true);
    }
  }

  const fetchData = async () => {
    try {
      await getSuppliers(itemsPerPge, skip);
      setLoadingSuppliers(false);
    } catch (error) {
      setLoadingSuppliers(false);
      setIsAllSuppliers(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filterBySearch = async (itemsPerPge, skip, searchQuery) => {
    const searched = await getSubjectsBySearch(itemsPerPge, skip, searchQuery);
    setPageCount(searched.data.pageCount);
    setAllSuppliers(searched.data.data);
    setCurrentPage(skip + 1);
  }

  const handlePagination = async (pageNumber) => {
    if (filterSupplier) {
      await filterBySearch(itemsPerPge, pageNumber - 1, searchQuery, selectedGradeFilter?.id, selectedSubjectFilter?.id, selectedTeacherFilter?.id);
    } else {
      await getSuppliers(itemsPerPge, pageNumber - 1);
      setCurrentPage(pageNumber);
    }
  }

  useEffect(() => {
  }, [isData]);

  const handleInputChange = (event) => {
    setIsError({ ...isError, [event.target.name]: "" });
    setIsData({ ...isData, [event.target.name]: event.target.value });
  };

  const isValidSupplier = (value) => {
    const errors = {};
    if (value.name.length < 3) {
      errors.name =
        "Supplier Name must be at least 3 characters long.";
    }
    if ((value.phone_number !== undefined && value.phone_number !== null)) {
      if (value.phone_number !== "") {
        if ((value.phone_number.length !== 10)) {
          errors.phone_number = "Value must be a valid phone number.";
        }
      }
    }
    if (Object.keys(errors).length > 0) {
      setIsError(errors);
      return false;
    } else {
      return true;
    }
  };

  const cleanData = (data) => {
    const cleanedData = { ...data };

    Object.keys(cleanedData).forEach((key) => {
      if (cleanedData[key] === "") {
        delete cleanedData[key];
      }
    });

    return cleanedData;
  };

  const insertNewSupplier = async () => {
    try {
      if (isValidSupplier(isData)) {
        setinsertButtonDisabled(true);
        const cleanedIsData = cleanData(isData);
        const response = await addNewSupplier(cleanedIsData);
        if (response.success === true) {
          setSuccessMessage("Succesfully Created the Supplier!");
          setSuccess(true);
          setIsData({
            name: "",
            phone_number: "",
            address: ""
          });
          setSelectedGradeForSupplier(null);
          fetchData();
          setinsertButtonDisabled(false);
        } else {
          setErrorMessage(response.message);
          setinsertButtonDisabled(false);
          setFailed(true);
        }
      }
    } catch (error) {
      setErrorMessage(error.message);
      setinsertButtonDisabled(false);
      setFailed(true);
    }
  };

  const handleEditClick = (Supplier) => {
    setSelectedSupplier(Supplier);
    setEditedSelectedSex(Supplier.gender);
    setEditedSelectedSyllabus(Supplier.syllabus);
    setEditedSelectedMedium(Supplier.medium);
    setEditedSelectedMode(Supplier.how_did_you_know_about_nenasala);
    setInitialSupplier(Supplier);
    setEditmodal(true);
  };

  const isValidEditSupplier = (value) => {
    const errors = {};
    if (value.name.length < 3) {
      errors.name =
        "Supplier Name must be at least 3 characters long.";
    }
    if ((value.phone_number !== undefined && value.phone_number !== null)) {
      if (value.phone_number !== "") {
        if ((value.phone_number.length !== 10)) {
          errors.phone_number = "Value must be a valid phone number.";
        }
      }
    }
    if (Object.keys(errors).length > 0) {
      setEditError(errors);
      setDisabledEditButton(false);
      return false;
    } else {
      return true;
    }
  };

  function getChangedValues(selectedSupplier) {
    const changedValues = {};
    for (const key in selectedSupplier) {
      if (
        key !== "id" &&
        selectedSupplier.hasOwnProperty(key) &&
        selectedSupplier[key] !== initialSupplier[key]
      ) {
        changedValues[key] = selectedSupplier[key];
      }

      if (key === "id") {
        changedValues[key] = selectedSupplier[key];
      }
    }
    return changedValues;
  }

  const editSupplierDetails = async () => {
    const validityCheck = isValidEditSupplier(selectedSupplier);
    if (validityCheck === true) {
      setEditedInput(false);
      const data = getChangedValues(selectedSupplier);
      const cleanedData = cleanData(data);
      const body = JSON.stringify(cleanedData);
      const response = await editSupplier(body);
      if (response.success === true) {
        setSuccessMessage(response.message);
        setSuccess(true);
        fetchData();
        setEditmodal(false);
      } else {
        setFailed(true);
      }
    }
  };

  const SupplierNew = {
    registration_number: '12553789',
    full_name: 'Nuwan Kavishka Ganepola Ganepola',
    image_url: '',
    barcode: '1001001'
  }
  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        <Modal
          className="modal-dialog-centered modal-success"
          isOpen={isSuccess}
          toggle={() => setSuccess(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isSuccessMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setSuccess(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-danger"
          isOpen={isFailed}
          toggle={() => setFailed(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isErrorMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setFailed(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Card className="mb-4 shadow">
          <CardHeader className="border-0">
            <h3 className="mb-0">Add New Supplier</h3>
          </CardHeader>
          <Form className="ml-4 mb-4 mr-4">
            <Row>
              <Col md="12" lg="8">
                <FormGroup>
                  <Input
                    id="fullName"
                    placeholder="Supplier's Name"
                    type="text"
                    value={isData.name}
                    onChange={handleInputChange}
                    name="name"
                  />
                  {isError.name && (
                    <p className="text-danger">{isError.name}</p>
                  )}
                </FormGroup>
              </Col>
              <Col md="12" lg="8">
                <FormGroup>
                  <Input
                    id="phone_number"
                    placeholder="Phone Number"
                    type="text"
                    value={isData.phone_number || ''}
                    onChange={handleInputChange}
                    name="phone_number"
                  />
                  {isError.phone_number && (
                    <p className="text-danger">{isError.phone_number}</p>
                  )}
                </FormGroup>
              </Col>
              <Col md="12" lg="8">
                <FormGroup>
                  <Input
                    id="address"
                    placeholder="Address"
                    type="text"
                    value={isData.address || ''}
                    onChange={handleInputChange}
                    name="address"
                  />
                  {isError.address && (
                    <p className="text-danger">{isError.address}</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  color="primary"
                  type="button"
                  onClick={() => {
                    insertNewSupplier();
                  }}
                  disabled={
                    !isData.name ||
                    isInsertButtonDisabled
                  }
                >
                  Insert
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
        {isLoadingSuppliers ? (
          <Card style={{}}>
            <Spinner className="m-10">Loading...</Spinner>
          </Card>
        ) : !isAllSuppliers ? (
          <Card className="text-center" style={{ padding: "1rem" }}>
            <img src={require("../../assets/img/brand/nodata.png")} className="noDataImage" />
          </Card>
        ) : (
          <Card className="shadow" style={{ padding: "1rem" }}>
            <CardHeader className="border-0">
              <Row className="mb-4">
                <Col>
                  <h3 className="mb-0">Suppliers</h3>
                </Col>
              </Row>
              <Row className="filterClass">
                <Col md="8" lg="4">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <Input
                        type="text"
                        placeholder="Supplier Name"
                        value={searchQuery}
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
            </CardHeader>
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Contact Number</th>                  
                  <th scope="col">Address</th>
                  <th scope="col">Status</th>
                  <th colSpan="3" className="actionTh">Actions</th>
                </tr>
              </thead>
              <tbody>
                {allSuppliers?.map((supplier, index) => (
                  <tr key={index}>
                    <td>{supplier.name}</td>
                    <td>{supplier.phone_number}</td>
                    <td>{supplier.address}</td>
                    <td>{supplier.status ? "Active" : "Inactive"}</td>
                    <td className="actionTd" style={{ textAlign: "center" }}>
                      <Button
                        color="primary"
                        type="button"
                        id="editSupplier"
                        onClick={() => handleEditClick(supplier)}
                      >
                        <BiEdit />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div style={{ paddingTop: "10px" }}>
              {pageCount > 1 ?
                <Paginations totalPages={pageCount} handlePagination={handlePagination} currentPage={currentPage}
                  currentStart={currentStart}
                  setCurrentStart={setCurrentStart}></Paginations>
                : null}
            </div>
          </Card>
        )}
        {/* Edit Supplier */}
        <Modal
          className="modal-dialog-centered"
          isOpen={editModal}
          toggle={() => [setEditmodal(false)]}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Edit Supplier
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => [
                setEditmodal(false),
                setDisabledEditButton(true),
                setEditError({
                  name: "",
                  phone_number: "",
                }),
              ]}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <ModalBody>
            <Form>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Name</Label>
                    <Input
                      id="editFullName"
                      placeholder="Name"
                      type="text"
                      value={
                        selectedSupplier?.name
                          ? selectedSupplier?.name
                          : ""
                      }
                      name="name"
                      onChange={(e) => {
                        setSelectedSupplier({
                          ...selectedSupplier,
                          name: e.target.value,
                        });
                        setEditedInput(true);
                        setDisabledEditButton(false);
                        setEditError({ name: "" });
                      }}
                    />
                    {isEditError.name && (
                      <p className="text-danger">{isEditError.name}</p>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label>Phone Number</Label>
                    <Input
                      id="editPhoneNumber"
                      placeholder="Phone Number"
                      type="text"
                      value={
                        selectedSupplier?.phone_number
                          ? selectedSupplier?.phone_number
                          : ""
                      }
                      name="phone_number"
                      onChange={(e) => {
                        setSelectedSupplier({
                          ...selectedSupplier,
                          phone_number: e.target.value,
                        });
                        setEditedInput(true);
                        setDisabledEditButton(false);
                        setEditError({ phone_number: "" });
                      }}
                    />
                    {isEditError.phone_number && (
                      <p className="text-danger">{isEditError.phone_number}</p>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label>Address</Label>
                    <Input
                      id="editAddress"
                      placeholder="Address"
                      type="text"
                      value={
                        selectedSupplier?.address
                          ? selectedSupplier?.address
                          : ""
                      }
                      name="address"
                      onChange={(e) => {
                        setSelectedSupplier({
                          ...selectedSupplier,
                          address: e.target.value,
                        });
                        setEditedInput(true);
                        setDisabledEditButton(false);
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                  <Dropdown isOpen={statusDropdownOpen}
                  toggle={() => setStatusDropdownOpen(!statusDropdownOpen)}>
                      <DropdownToggle caret>
                        {selectedSupplier
                          ? selectedSupplier.status
                            ? "Active"
                            : "Inactive"
                          : "Select Status"}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={(e) => {
                            setSelectedSupplier({
                              ...selectedSupplier,
                              status: true,
                            });
                            setEditedInput(true);
                            setDisabledEditButton(false);
                          }}
                        >
                          Active
                        </DropdownItem>
                        <DropdownItem
                          onClick={(e) => {
                            setSelectedSupplier({
                              ...selectedSupplier,
                              status: false,
                            });
                            setEditedInput(true);
                            setDisabledEditButton(false);
                          }}
                        >
                          Inactive
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>

                  <Button
                    color="primary"
                    type="button"
                    onClick={() => editSupplierDetails()}
                    disabled={!isEditedInput || isDisabledEditButton}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div >
    </>
  );
};

export default Suppliers;
