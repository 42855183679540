import ClassAssistantAssignStudent from "views/pages/ClassAssistant/ClassAssistantAssignStudent";
import ClassAssistantServicePayments from "views/pages/ClassAssistant/ClassAssistantServicePayments";
import ClassAssistantStudentPayments from "views/pages/ClassAssistant/ClassAssistantStudentPayments";
import ClassAssistantStudentPayType from "views/pages/ClassAssistant/ClassAssistantStudentPayType";
import ClassAssistantSupplierOrders from "views/pages/ClassAssistant/ClassAssistantSupplierOrders";
import ClassAssistantTeacherPayments from "views/pages/ClassAssistant/ClassAssistantTeacherPayments";
import ClassAssistantStudent from "views/pages/ClassAssistant/ClassAssistnatStudent";

var classAssistantRoutes = [
    {
        path: "/student",
        name: "Student",
        icon: "ni fa fa-users text-nenasala",
        component: <ClassAssistantStudent />,
        layout: "/classassistant",
    },
    {
        path: "/payments",
        name: "Payments",
        icon: "ni fa-regular fa-credit-card text-nenasala",
        component: <ClassAssistantStudentPayments />,
        layout: "/classassistant",
    },
    {
        path: "/teacher-payments",
        name: "Receipts",
        icon: "ni fa-solid fa-file-invoice text-nenasala",
        component: <ClassAssistantTeacherPayments />,
        layout: "/classassistant",
    },
    {
        path: "/neansala-services",
        name: "Services",
        icon: "ni fa fa-money-bill text-nenasala",
        component: <ClassAssistantServicePayments />,
        layout: "/classassistant",
    },
    {
        path: "/student-assign",
        name: "Assign",
        icon: "ni fa fa-user text-nenasala",
        component: <ClassAssistantAssignStudent />,
        layout: "/classassistant",
    },
    {
        path: "/supplier-orders",
        name: "Orders",
        icon: "ni fa fa-user text-nenasala",
        component: <ClassAssistantSupplierOrders />,
        layout: "/classassistant",
    },
];

export default classAssistantRoutes;