import {
  Card,
  CardHeader,
  Row,
  Col,
  CardBody,
  CardFooter,
} from "reactstrap";
import React, { useEffect, useState } from 'react';
import "../../assets/css/qr-card-styles.css";
import Barcode from "react-barcode";
import Logo from '../../assets/img/brand/nenasala-logo.png';
import BlankImage from '../../assets/img/brand/blank_profile_pic.png';
import Background from '../../assets/img/brand/nenasala-bg-1.png';


// const StudentIdCard = ({ student }) => {
//     return (
//         <Card
//             className="qRCard"
//             style={{
//                 position: 'relative',
//                 margin: 'auto',
//                 color: 'white',
//                 borderRadius: '20px',
//                 textAlign: 'center',
//                 padding: '20px',
//                 overflow: 'hidden',
//             }}
//         >
//             <div
//                 style={{
//                     position: 'absolute',
//                     top: 0,
//                     left: 0,
//                     width: '100%',
//                     height: '120px',
//                     // backgroundColor: '#021341',
//                     zIndex: 0,
//                 }}
//                 className="shape"
//             ></div>

//             <div
//                 style={{
//                     position: 'absolute',
//                     bottom: 0,
//                     left: 0,
//                     width: '100%',
//                     height: '10px',
//                     backgroundColor: '#021341',
//                     zIndex: 0,
//                 }}
//             ></div>
//             <CardHeader
//                 className="idCardHeader"
//                 style={{ borderBottom: 'none', backgroundColor: 'transparent', position: 'relative', zIndex: 10 }}
//             >
//                 <img src={Logo} alt="Institute Logo" width={60} crossOrigin="anonymous" />
//                 <p className="instName">
//                         Nenasala<br/>
//                         Balangoda
//                     </p>
//             </CardHeader>
//             <CardBody className="qrBody" style={{ zIndex: 1, position: 'relative' }}>
// <img src={
//     student.image_url
//         ? student.image_url
//         : BlankImage
// } className="stuImageId" width={90} borderRadius={5} crossOrigin="Access-Control-Allow-Origin" />
//                 <Row className="studentNameSection">
//                     <p className="regNumber">
//                         {student.registration_number}
//                     </p>
//                     <p className="title">
//                         {student.full_name.toUpperCase()}
//                     </p>
//                 </Row>
//             </CardBody>
// <Barcode
//     className="barCodeId"
//     value={student.barcode}
//     height={40}
//     fontSize={12}
//     style={{ position: 'relative', zIndex: 1 }}
// />
//         </Card>

//     );
// };

// export default StudentIdCard;

const StudentIdCard = ({ student }) => {

  return (
    <div style={styles.card}>
      <div style={styles.header}>
        <img src={Logo} alt="Institute Logo" width={100} crossOrigin="anonymous" />
      </div>
      <div style={styles.studentInfo}>
        <h5 style={{ marginBottom: '0px', height: '6mm', lineHeight: '1' }}>{student.full_name}</h5>
        <img src={
          student.image_url
            ? student.image_url
            : BlankImage
        } style={styles.photoPlaceholder} crossOrigin="Access-Control-Allow-Origin" />
        <h6 style={{ marginBottom: '0px' }}>STUDENT ID CARD</h6>
      </div>
      <Barcode
        className="barCodeId"
        value={student.barcode}
        height={40}
        fontSize={12}
        style={{ position: 'relative', zIndex: 1 }}
      />
      <div style={styles.contactInfoSec}>
        <p style={styles.contactInfo}>No 18/1, Kalthota Road, Balangoda</p>
        <p style={styles.contactInfo}>Tele: 0452289889 / 0452286646</p>
        <p style={styles.contactInfo}>WhatsApp: 0777775358</p>
      </div>
    </div>
  );
};

const styles = {
  card: {
    width: '55mm',
    height: '87mm',
    backgroundColor: '#fff',
    padding: '10px',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    fontFamily: 'Arial, sans-serif',
    backgroundImage: `url(${Background})`,  // Add the background image URL here
    backgroundSize: 'cover',                // Adjust this to 'contain' if you prefer
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  header: {
    textAlign: 'center',
    marginBottom: '10px',
  },
  logo: {
    width: '50px',
    height: '50px',
  },
  instituteName: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  instituteDetails: {
    fontSize: '12px',
    color: '#555',
  },
  studentInfo: {
    textAlign: 'center',
    padding: '0px 10px'
  },
  photoPlaceholder: {
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    backgroundColor: '#d9d9d9',
    margin: '10px auto',
  },
  barcode: {
    display: 'block',
    margin: '10px auto',
  },
  contactInfoSec: {
    textAlign: 'center',
    fontSize: '10px',
    color: '#333',
  },
  contactInfo: {
    textAlign: 'center',
    fontSize: '8px',
    color: '#333',
  },
};

export default StudentIdCard;
