import {
  FormGroup,
  Form,
  Input,
  InputGroup,
  Row,
  Col,
  Table,
  Card,
  CardHeader,
  Modal,
  Button,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from "reactstrap";
import { useState, useEffect, useRef } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import Paginations from "components/Pagination/Paginations";
import { getUser } from "actions/Auth";
import DatePicker from "react-datepicker";
import { getAllActiveSuppliers } from "actions/Suppliers";
import { addNewSupplierOrder } from "actions/Suppliers";
import { filterSupplierOrders } from "actions/Suppliers";

const ClassAssistantSupplierOrders = () => {
  const date = new Date();
  const [isServices, setServices] = useState(false);
  const [isLoadingServices, setLoadingServices] = useState(true);

  const [allActiveServices, setAllActiveServices] = useState(null);

  const [isData, setData] = useState({
    description: '',
    amount: '',
    quantity: '',
    date: '',
    userId: '',
    supplierId: ''
  });

  const [isError, setError] = useState({
    description: '',
    amount: '',
    supplier: ''
  });
  const [isSuccess, setSuccess] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [isSuccessMessage, setSuccessMessage] = useState("");
  const [isErrorMessage, setErrorMessage] = useState("");
  const [isInsertButtonDisabled, setInsertButtonDisabled] = useState(false);
  const [serviceTypeDropdownOpen, setServiceTypeDropdownOpen] = useState(false);
  const [selectedtServiceType, setSelectedtServiceType] = useState(null);
  const [user, setUser] = useState(null);

  const getSuppliers = async () => {
    try {
      const services = await getAllActiveSuppliers();
      setAllActiveServices(services.filter(services => services.status = 'ACTIVE'));
    } catch (error) {
      setAllActiveServices(null);
    }
  }

  const fetchData = async () => {
    try {
      const user = await getUser();
      setUser(user.id);
      await getSuppliers();
    } catch (error) {
      setLoadingServices(false);
      setServices(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleInputChange = (event) => {
    setError({ ...isError, [event.target.name]: "" });
    if (event.target.name === 'amount' || event.target.name === 'quantity') {
      if (/^\d*$/.test(event.target.value)) {
        setData({ ...isData, [event.target.name]: event.target.value });
      }
    } else {
      setData({ ...isData, [event.target.name]: event.target.value });
    }
  };

  const isValidPayment = (value) => {
    const errors = {};
    if (value.description.length < 3) {
      errors.description =
        "Description must be at least 3 characters long.";
    }
    if (!value.amount) {
      errors.amount =
        "Amount is Required.";
    }
    if (!value.supplierId) {
      errors.nenasalaService =
        "Nenasala Service is Required.";
    }
    if (Object.keys(errors).length > 0) {
      setError(errors);
      return false;
    } else {
      return true;
    }
  };

  const insertNewPayment = async () => {
    try {
      if (isValidPayment(isData)) {
        setInsertButtonDisabled(true);
        isData.date = date;
        isData.amount = +(isData.amount);
        isData.userId = +user;
        isData.supplierId = +(isData.supplierId);
        if (isData.quantity.length > 0) {
          isData.quantity = +(isData.quantity);
        } else {
          delete isData.quantity;
        }
        const response = await addNewSupplierOrder(isData);
        if (response.success === true) {
          setData({
            description: '',
            amount: '',
            quantity: '',
          });
          setSuccess(true);
          setSuccessMessage('Successfully created the Order.')
          setSelectedtServiceType(null);
          setInsertButtonDisabled(false);
        } else {
          setFailed(true);
          setErrorMessage('Failed to make the payment. Try Again!');
          setInsertButtonDisabled(false);
        }
      }
    } catch (error) {
      setFailed(true);
      setErrorMessage('Failed to make the payment. Try Again!');
      setInsertButtonDisabled(false);
    }
  }

  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        <Modal
          className="modal-dialog-centered modal-success"
          isOpen={isSuccess}
          toggle={() => setSuccess(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isSuccessMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setSuccess(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-danger"
          isOpen={isFailed}
          toggle={() => setFailed(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isErrorMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setFailed(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Card className="mb-4 shadow">
          <CardHeader className="border-0">
            <Row>
              <Col md="6" xs="12">
                <span>
                  <h3 className="mb-0">Add New Supplier Transaction</h3>
                </span>
              </Col>
            </Row>
          </CardHeader>

          <Form className="ml-4 mb-4 mr-4">
            <Row>
              <Col md="6">
                <FormGroup>
                  <Dropdown
                    isOpen={serviceTypeDropdownOpen}
                    toggle={() => setServiceTypeDropdownOpen(!serviceTypeDropdownOpen)}
                  >
                    <DropdownToggle caret>
                      {selectedtServiceType
                        ? selectedtServiceType
                        : "Select a Supplier"}
                    </DropdownToggle>
                    <DropdownMenu style={{ heigmaxHeightt: '400px', overflowY: 'scroll' }}>
                      {allActiveServices && [...allActiveServices]
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((service) => (
                          <DropdownItem
                            key={service.name}
                            value={service.id}
                            onClick={() => [
                              setData({
                                ...isData, supplierId: service.id
                              }),
                              setSelectedtServiceType(service.name),
                              setError({ ...isError, nenasalaService: '' })
                            ]}
                          >
                            {service.name}
                          </DropdownItem>
                        ))}
                    </DropdownMenu>
                  </Dropdown>
                  {isError.nenasalaService && (
                    <p className="text-danger">{isError.nenasalaService}</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    id="description"
                    placeholder="Description"
                    type="text"
                    value={isData.description}
                    name='description'
                    required
                    onChange={handleInputChange}
                  />
                  {isError.description && (
                    <p className="text-danger">{isError.description}</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    id="quantity"
                    placeholder="Quantity"
                    type="text"
                    value={isData.quantity}
                    name='quantity'
                    required
                    onChange={handleInputChange}
                  />
                  {isError.quantity && (
                    <p className="text-danger">{isError.quantity}</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    id="amount"
                    placeholder="Amount"
                    type="text"
                    value={isData.amount}
                    name='amount'
                    required
                    onChange={handleInputChange}
                  />
                  {isError.amount && (
                    <p className="text-danger">{isError.amount}</p>
                  )}
                </FormGroup>
                <Button
                  color="primary"
                  type="button"
                  onClick={insertNewPayment}
                  disabled={isInsertButtonDisabled}
                >
                  Insert
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default ClassAssistantSupplierOrders;
