import axios from "axios";
const apiUrl = "https://nenasala-balangoda.ardillalabs.cloud/api"; 

export const getAllExams = async (skip, limit) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/exam/get-all-exams`
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
        reject({
          response: {
            data: {
              message: 'Unable to extract exam details.',
              status: err.code,
              statusText: err.code,
            },
          },
        });
    }
  });
};

export const addNewExam = async (addExamData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.post(
        `${apiUrl}/exam/create-exam`,
        addExamData,
        config
      );

      if (response.data) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
        reject({
          response: {
            data: {
              message: "Failed to insert exam.",
              status: err.code,
              statusText: err.code,
            },
          },
        });
    }
  });
};

export const editExam = async (editExamData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.put(
        `${apiUrl}/exam/update-exam-info`,
        editExamData,
        config
      );

      if (response.data.success === true) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
        reject({
          response: {
            data: {
              message: "Unable to update exam details.",
              status: err.code,
              statusText: err.code,
            },
          },
        });
    }
  });
};

export const filterExams = async (teacherClassId, teacherId, skip, limit) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/exam?skip=${skip}&limit=${limit}&teacherId=${teacherId}&teacherClassId=${teacherClassId}`,
        config
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
        reject({
          response: {
            data: {
              message: 'Unable to extract exam details.',
              status: err.code,
              statusText: err.code,
            },
          },
        });
    }
  });
};

export const filterStudentByExam = async (teacherClassId, skip, limit) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/class-user?skip=${skip}&limit=${limit}&teacherClassId=${teacherClassId}`,
        config
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
        reject({
          response: {
            data: {
              message: 'Unable to extract exam details.',
              status: err.code,
              statusText: err.code,
            },
          },
        });
    }
  });
};

export const filterStudentBySearch = async (teacherClassId, searchTerm) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/class-user?skip=0&limit=10&teacherClassId=${teacherClassId}&search=${searchTerm}`,
        config
      );

      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
        reject({
          response: {
            data: {
              message: 'Unable to extract exam details.',
              status: err.code,
              statusText: err.code,
            },
          },
        });
    }
  });
};

export const addExamResults = async (addExamData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.post(
        `${apiUrl}/exam-result/create-exam-result`,
        addExamData,
        config
      );

      if (response.data) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
        reject({
          response: err.response
        });
    }
  });
};

export const getExamLeaderBoard = async (teacherClassId, examId, skip, limit) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/exam-result/leaderboard?skip=${skip}&limit=${limit}&teacherClassId=${teacherClassId}&examId=${examId}&sort=mark`,
        config
      );

      if (response.data) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
        reject({
          response: err.response
        });
    }
  });
};

export const getExamLeaderBoardGraph = async (examId, maxMarks) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/exam-result/leaderboard-graph?min_mark=0&max_mark=${maxMarks}&examId=${examId}`,
        config
      );

      if (response.data) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
        reject({
          response: err.response
        });
    }
  });
};