import Index from "views/Index.js";
import Profile from "views/pages/Profile.js";
import Login from "views/pages/Login.js";
import Students from "views/pages/HighLevelAdmin/Students";
import ClassStudentDetails from "views/pages/HighLevelAdmin/ClassStudentDetails";
import ClassStudentPaymentRecords from "views/pages/HighLevelAdmin/ClassStudentPaymentRecords";
import Teachers from "views/pages/HighLevelAdmin/Teachers";
import ClassTeacherDetails from "views/pages/HighLevelAdmin/ClassTeacherDetails";
import TeacherPaymentDetails from "views/pages/HighLevelAdmin/TeacherPaymentDetails";
import ClassStudentList from "views/pages/HighLevelAdmin/ClassStudentList";
import ClassTeacherPaymentRecords from "views/pages/HighLevelAdmin/ClassTeacherPaymentRecords";
import ClassTeacherDailyPaymentRecords from "views/pages/HighLevelAdmin/ClassTeacherDailyPaymentRecords";
import AttendanceSheet from "views/pages/HighLevelAdmin/AttendanceSheet";
import Grades from "views/pages/HighLevelAdmin/Grades";
import Subjects from "views/pages/HighLevelAdmin/Subjects";

var highLevelRoutes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-nenasala",
    component: <Index />,
    layout: "/high-level-admin",
  }, 
  {
    path: "/students",
    name: "Students",
    icon: "ni fa fa-users text-nenasala",
    component: <Students />,
    layout: "/high-level-admin",
  },
  {
    path: "/teachers",
    name: "Teachers",
    icon: "ni fa fa-graduation-cap text-nenasala",
    component: <Teachers/>,
    layout: "/high-level-admin",
  },
  {
    path: "/grades",
    name: "Grades",
    icon: "ni ni-paper-diploma text-nenasala",
    component: <Grades/>,
    layout: "/high-level-admin",
  },
   {
    path: "/subjects",
    name: "Subjects",
    icon: "ni ni-book-bookmark text-nenasala",
    component: <Subjects/>,
    layout: "/high-level-admin",
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-nenasala",
    component: <Profile />,
    layout: "/high-level-admin",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },
  {
    path: "/students/:id",
    name: "Entrolled Classes",
    icon: "ni ni-bus-front-12 text-orange",
    component: <ClassStudentDetails />,
    layout: "/high-level-admin",
  },
  {
    path: "/students/payment-records/:id",
    name: "Payment Records",
    icon: "ni ni-bus-front-12 text-orange",
    component: <ClassStudentPaymentRecords />,
    layout: "/high-level-admin",
  },
  {
    path: "/teachers/:id",
    name: "Teacher Class Details",
    icon: "ni ni-bus-front-12 text-orange",
    component: <ClassTeacherDetails />,
    layout: "/high-level-admin",
  },
  {
    path: "/teachers/payments/:teacherId/:full_name",
    name: "Class Teacher Payment Records",
    icon: "ni ni-bus-front-12 text-orange",
    component: <TeacherPaymentDetails />,
    layout: "/high-level-admin",
  },
  {
    path: "/teachers/classStudents/:classTeacherId",
    name: "Class Teacher Students",
    icon: "ni ni-bus-front-12 text-orange",
    component: <ClassStudentList />,
    layout: "/high-level-admin",
  }, 
  {
    path: "/teachers/payment-records/:teacherClassId/:month",
    name: "Class Teacher Daily Payment Records",
    icon: "ni ni-bus-front-12 text-orange",
    component: <ClassTeacherPaymentRecords />,
    layout: "/high-level-admin",
  },
  {
    path: "/teachers/daily-payment-records/:teacherClassId/:date",
    name: "Class Teacher Daily Payment Records",
    icon: "ni ni-bus-front-12 text-orange",
    component: <ClassTeacherDailyPaymentRecords />,
    layout: "/high-level-admin",
  },
  {
    path: "/teachers/attendance-records/:teacherClassId/:date",
    name: "Class Teacher Payment Records",
    icon: "ni ni-bus-front-12 text-orange",
    component: <AttendanceSheet />,
    layout: "/high-level-admin",
  },
];

export default highLevelRoutes;
