import React from 'react';
import '../../assets/css/receipt-styles.css';

const ServicePaymentReceipt = React.forwardRef((props, ref) => {
  const invoiceDate = props.allDetails.invoiceDate;
  const date = new Date(props.allDetails.invoiceDate);

  return (
    <>
      <style>
        {`
      @media print {
          @page {
              size: 80mm 80mm;
              margin: 0;
          }
      }
  `}
      </style>
      <div className="payReceipt m-5" ref={ref}>
        <div className='studentReceiptHeader'>
          <h2 style={{ color: 'black' }}>Balangoda Nenasala Information Technology School</h2>
        </div>
        <div className='studentPayDetails'>
          <h2 style={{ fontSize: 36, color: 'black' }}>Invoice No: {props.allDetails.invoiceNumber}</h2>
          <h2 style={{ fontSize: 36, color: 'black' }}>Invoice Date: {invoiceDate}</h2>
          <h2 style={{ fontSize: 36, color: 'black' }}>Service: {props.allDetails.service}</h2>
          <h2 style={{ fontSize: 36, color: 'black' }}>Description: {props.allDetails.description}</h2>
          <h2 style={{ fontSize: 36, color: 'black' }}>Paid Amount: {Number(props.allDetails.amount).toFixed(2)}</h2>
        </div>
        <div className='studentReceiptBarcodeSection'>
          <h2 style={{ fontSize: 36 }}>Created By {props.allDetails.createdBy}</h2>
        </div>
        <hr style={{ height: 2, color: 'black' }} />
        <div className='studentReceiptInstSection'>
         <h2 style={{ fontSize: 25, color: 'black', fontWeight: 'bold' }}>No 18/1, Kalthota Road, Balagahamula, Balangoda.</h2>
          <h2 style={{ fontSize: 25, color: 'black', fontWeight: 'bold' }}> Telephone: +94 45 2289889 / +94 45 2286646
          <h2 style={{ fontSize: 25, color: 'black', fontWeight: 'bold' }}> Mobile/WhatsApp: +94 70 777 5358</h2>
          </h2>
        </div>
      </div>
    </>
  );
});

export default ServicePaymentReceipt;