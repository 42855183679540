// reactstrap components
import {
  Row,
  Col,
  Table,
  Card,
  Button,
  Spinner,
  FormGroup,
  Modal,
  ModalBody,
  Form,
  Input,
} from "reactstrap";
// core components
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import { getClassDetailsByTeacherId } from "actions/Class";
import { getUser } from "actions/Auth";
import { Link, Route } from "react-router-dom";
import { HiDownload } from "react-icons/hi";
import { downloadMonthlyIncomeReport } from "actions/Teachers";
import "../../../assets/css/teacher-styles.css";
import TeacherPaymentRecords from "../TeacherPayments";

const TeacherClassDetails = () => {
  const [allClassDetails, setAllClassDetails] = useState(null);
  const [isClassDetails, setClassDetails] = useState(false);
  const [isLoadingClassDetails, setLoadingclassDetailss] = useState(true);
  const [today, setToday] = useState(null);
  const [todayDate, setTodayDate] = useState(null);
  const [user, setUser] = useState(null);
  const [previousMonth, setPreviousMonth] = useState(null);
  const [isFailed, setFailed] = useState(false);
  const [isErrorMessage, setErrorMessage] = useState("");
  const [editModal, setEditmodal] = useState(false);
  const [selectedTeacherClass, setSelectedTeacherClass] = useState({
    id: null,
    name: "",
    status: "",
  });  
  const [editErrors, setEditErrors] = useState({});

  const fetchData = async () => {
    try {
      const user = await getUser();
      setUser(user);
      const classDetails = await getClassDetailsByTeacherId(user.id);
      setAllClassDetails(classDetails);
      setLoadingclassDetailss(false);
      setClassDetails(true);
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;
      const day = currentDate.getDate();
      setToday({
        year: year,
        month: month,
        day: day,
      });
      setTodayDate({
        year: year,
        month: month.toString().padStart(2, "0"),
        day: day.toString().padStart(2, "0"),
      });
      currentDate.setMonth(currentDate.getMonth() - 1);
      const yearPrevious = currentDate.getFullYear();
      const monthPrevious = String(currentDate.getMonth() + 1).padStart(2, '0');
      setPreviousMonth({
        year: yearPrevious,
        month: monthPrevious
      });
    } catch (error) {
      setLoadingclassDetailss(false);
      setClassDetails(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const downloadBlob = async () => {
    try {
      await downloadMonthlyIncomeReport(`${todayDate.year}-${todayDate.month}`, user?.id, user?.full_name);
    } catch (err) {
      setFailed(true);
      setErrorMessage(err.message);
    }
  };

  const downloadBlobPreviousMonth = async () => {
    try {
      await downloadMonthlyIncomeReport(`${previousMonth.year}-${previousMonth.month}`, user?.id, user?.full_name);
    } catch (err) {
      setFailed(true)
      setErrorMessage(err.message)
    }
  };
  
  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        <Modal
          className="modal-dialog modal-danger"
          isOpen={isFailed}
          toggle={() => setFailed(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isErrorMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setFailed(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        {isLoadingClassDetails ? (
          <Card>
            <Spinner className="m-10">Loading...</Spinner>
          </Card>
        ) : !isClassDetails ? (
          <Card className="text-center" style={{ padding: "1rem" }}>
            <img src={require("../../../assets/img/brand/nodata.png")} className="noDataImage" alt="No Data" />
          </Card>
        ) : (
          <Card className="shadow" style={{ padding: "2rem" }}>
            <Row className="teacherPaymentHeader">
              <Col>
                <h3 className="mb-0">Class Details</h3>
              </Col>
              <Col className="btnSection" >
                <FormGroup className="mb-0">
                  <Button
                    color="primary"
                    type="button"
                    onClick={() => downloadBlob()}
                    className="monthBtn"
                  >
                    <HiDownload /> This Month
                  </Button>
                </FormGroup>
                <FormGroup>
                  <Button
                    color="primary"
                    type="button"
                    onClick={() => downloadBlobPreviousMonth()}
                    className="monthBtn"
                  >
                    <HiDownload /> Previous Month
                  </Button>
                </FormGroup>
              </Col>
            </Row>
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Subject</th>
                  <th scope="col">Grade</th>
                  <th scope="col">Status</th>
                  <th scope="col" style={{ textAlign: "center" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {allClassDetails?.map((classDetails, index) => (
                  <tr key={index}>
                    <td>{classDetails.subject.name}</td>
                    <td>{classDetails.grade.name}</td>
                    <td>{classDetails.status ? "Active" : "Inactive"}</td>
                    <td style={{ textAlign: "center" }}>
                    <Button
                        color="default"
                        type="button"
                        tag={Link}
                        to={`/teacher/classStudents/${classDetails.id}`}
                      >
                        Students
                      </Button>
                      {classDetails.payment_type === "MONTHLY" ?
                        <Button
                          color="default"
                          type="button"
                          tag={Link}
                          to={`/teacher/payment-records/${classDetails.id}/${todayDate.year}-${todayDate.month}`}
                        >
                          Payment Records
                        </Button> :
                        <Button
                          color="default"
                          type="button"
                          tag={Link}
                          to={`/teacher/daily-payment-records/${classDetails.id}/${todayDate.year}-${todayDate.month}-${todayDate.day}`}
                        >
                          Payment Records
                        </Button>
                      }
                      {/* <Button
                        color="primary"
                        type="button"
                        tag={Link}
                        to={`/teacher/tutes/${classDetails.id}`}
                      >
                        Tutes
                      </Button> */}
                      <Button
                        color="primary"
                        type="button"
                        tag={Link}
                        to={`/teacher/exam/${classDetails.id}/${classDetails.teacher.id}`}
                      >
                        Exams
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        )}
      </div>
    </>
  );
};

export default TeacherClassDetails;
