import {
  FormGroup,
  Form,
  Input,
  InputGroup,
  Row,
  Col,
  Table,
  Card,
  CardHeader,
  Modal,
  Button,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from "reactstrap";
import { useState, useEffect, useRef } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import Paginations from "components/Pagination/Paginations";
import { getUser } from "actions/Auth";
import DatePicker from "react-datepicker";
import { getAllActiveSuppliers } from "actions/Suppliers";
import { addNewSupplierOrder } from "actions/Suppliers";
import { filterSupplierOrders } from "actions/Suppliers";

const SupplierTransactions = () => {
  const date = new Date();

  const [allServices, setAllServices] = useState(null);
  const [isServices, setServices] = useState(false);
  const [isLoadingServices, setLoadingServices] = useState(true);

  const [allActiveServices, setAllActiveServices] = useState(null);

  const [isData, setData] = useState({
    description: '',
    amount: '',
    quantity: '',
    date: '',
    userId: '',
    supplierId: ''
  });

  const [isError, setError] = useState({
    description: '',
    amount: '',
    supplier: ''
  });
  const [isSuccess, setSuccess] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [isSuccessMessage, setSuccessMessage] = useState("");
  const [isErrorMessage, setErrorMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPge, setItemsPerPage] = useState(10);
  const [skip, setSkip] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentStart, setCurrentStart] = useState(0);
  const [isInsertButtonDisabled, setInsertButtonDisabled] = useState(false);
  const [serviceTypeDropdownOpen, setServiceTypeDropdownOpen] = useState(false);
  const [selectedtServiceType, setSelectedtServiceType] = useState(null);
  const [user, setUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterServiceTypeDropdownOpen, setFilterServiceTypeDropdownOpen] = useState(false);
  const [selectedtFilterServiceType, setSelectedtFilterServiceType] = useState(null);

  const filterDate = new Date();
  const filterYear = filterDate.getFullYear();
  const filterMonth = (filterDate.getMonth() + 1).toString().padStart(2, "0");
  const filterDay = filterDate.getDate().toString().padStart(2, "0");
  const freshMonth = filterYear + "-" + filterMonth
  const freshDate = filterYear + "-" + filterMonth + "-" + filterDay;
  const [selectedDate, setSelectedDate] = useState(freshDate);
  const [isDate, setDate] = useState(true);
  const [isMonth, setMonth] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);

  const [months, setMonths] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);

  const getSuppliers = async () => {
    try {
      const services = await getAllActiveSuppliers();
      setAllActiveServices(services.filter(services => services.status = 'ACTIVE'));
    } catch (error) {
      setAllActiveServices(null);
    }
  }

  const filterServicePaymentList = async (date, serviceId) => {
    try {
      const response = await filterSupplierOrders(date, serviceId);
      if (response.data.data.length > 0) {
        setAllServices(response.data.data);
        setPageCount(response.data.pageCount);
        setLoadingServices(false);
        setServices(true);
        const totalAmount = response.data.data.reduce((acc, item) => acc + item.amount, 0);
        setTotalAmount(totalAmount);
      } else {
        setAllServices(null);
        setServices(false);
        setLoadingServices(false);
      }
    } catch (error) {
      setAllServices(null);
      setLoadingServices(false);
    }
  }
  const fetchData = async (itemsPerPge, skip) => {
    try {
      const user = await getUser();
      setUser(user.id);
      await getSuppliers();
      const startDate = new Date();
      const endDate = new Date();
      startDate.setMonth(startDate.getMonth() - (12 - 1));
      const startMonth = startDate.getMonth();
      const endMonth = endDate.getMonth();
      const startYear = startDate.getFullYear();
      const endYear = endDate.getFullYear();
      const months = [];

      for (let year = startYear; year <= endYear; year++) {
        const monthStart = year === startYear ? startMonth : 0;
        const monthEnd = year === endYear ? endMonth : 11;

        for (let month = monthStart; month <= monthEnd; month++) {
          const monthString = `${year}-${(month + 1)
            .toString()
            .padStart(2, "0")}`;
          months.push({
            label: monthString,
            month: month + 1,
            year: year,
          });
        }
      }
      setMonths(months);
      await filterServicePaymentList(selectedDate, selectedtFilterServiceType?.id ? selectedtFilterServiceType.id : null);
    } catch (error) {
      setLoadingServices(false);
      setServices(false);
    }
  };

  useEffect(() => {
    fetchData(itemsPerPge, skip);
  }, []);

  const handlePagination = async (pageNumber) => {
    if (searchQuery !== " ") {
      // await filterBySearch(itemsPerPge, pageNumber - 1, searchQuery);
    } else {
      const features = await fetchData(itemsPerPge, pageNumber - 1);
      setCurrentPage(pageNumber);
    }
  }

  const handleInputChange = (event) => {
    setError({ ...isError, [event.target.name]: "" });
    if (event.target.name === 'amount' || event.target.name === 'quantity') {
      if (/^\d*$/.test(event.target.value)) {
        setData({ ...isData, [event.target.name]: event.target.value });
      }
    } else {
      setData({ ...isData, [event.target.name]: event.target.value });
    }
  };

  const isValidPayment = (value) => {
    const errors = {};
    if (value.description.length < 3) {
      errors.description =
        "Description must be at least 3 characters long.";
    }
    if (!value.amount) {
      errors.amount =
        "Amount is Required.";
    }
    if (!value.supplierId) {
      errors.nenasalaService =
        "Nenasala Service is Required.";
    }
    if (Object.keys(errors).length > 0) {
      setError(errors);
      return false;
    } else {
      return true;
    }
  };

  const insertNewPayment = async () => {
    try {
      if (isValidPayment(isData)) {
        setInsertButtonDisabled(true);
        isData.date = date;
        isData.amount = +(isData.amount);
        isData.userId = +user;
        isData.supplierId = +(isData.supplierId);
        if (isData.quantity.length > 0) {
          isData.quantity = +(isData.quantity);
        } else {
          delete isData.quantity;
        }
        const response = await addNewSupplierOrder(isData);
        if (response.success === true) {
          setData({
            description: '',
            amount: '',
            quantity: '',
          });
          setSuccess(true);
          setSuccessMessage('Successfully created the Order.')
          setSelectedtServiceType(null);
          setInsertButtonDisabled(false);
        } else {
          setFailed(true);
          setErrorMessage('Failed to make the payment. Try Again!');
          setInsertButtonDisabled(false);
        }
      }
    } catch (error) {
      setFailed(true);
      setErrorMessage('Failed to make the payment. Try Again!');
      setInsertButtonDisabled(false);
    }
  }

  const filterByDate = async (date, selectedServiceType) => {
    try {
      setDate(true);
      setMonth(false);
      setSelectedMonth(null);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const newDate = year + "-" + month + "-" + day;
      setSelectedDate(newDate);
      await filterServicePaymentList(newDate, selectedServiceType);
    } catch (error) {
      setAllServices(null);
    }
  }

  const filterClick = async (label) => {
    setDate(false);
    setMonth(true);
    setSelectedDate(freshDate)
    setSelectedMonth(label);
    await filterServicePaymentList(label, selectedtFilterServiceType?.id ? selectedtFilterServiceType.id : null);
  }

  const resetFilter = async () => {
    setSelectedtFilterServiceType(null);
    setSelectedDate(freshDate);
    setSelectedMonth(null);
    await filterServicePaymentList(freshDate, null);
  }

  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        <Modal
          className="modal-dialog-centered modal-success"
          isOpen={isSuccess}
          toggle={() => setSuccess(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isSuccessMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setSuccess(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-danger"
          isOpen={isFailed}
          toggle={() => setFailed(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isErrorMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setFailed(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Card className="mb-4 shadow">
          <CardHeader className="border-0">
            <Row>
              <Col md="6" xs="12">
                <span>
                  <h3 className="mb-0">Add New Supplier Transaction</h3>
                </span>
              </Col>
            </Row>
          </CardHeader>

          <Form className="ml-4 mb-4 mr-4">
            <Row>
              <Col md="6">
                <FormGroup>
                  <Dropdown
                    isOpen={serviceTypeDropdownOpen}
                    toggle={() => setServiceTypeDropdownOpen(!serviceTypeDropdownOpen)}
                  >
                    <DropdownToggle caret>
                      {selectedtServiceType
                        ? selectedtServiceType
                        : "Select a Supplier"}
                    </DropdownToggle>
                    <DropdownMenu style={{ heigmaxHeightt: '400px', overflowY: 'scroll' }}>
                      {allActiveServices && [...allActiveServices]
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((service) => (
                          <DropdownItem
                            key={service.name}
                            value={service.id}
                            onClick={() => [
                              setData({
                                ...isData, supplierId: service.id
                              }),
                              setSelectedtServiceType(service.name),
                              setError({ ...isError, nenasalaService: '' })
                            ]}
                          >
                            {service.name}
                          </DropdownItem>
                        ))}
                    </DropdownMenu>
                  </Dropdown>
                  {isError.nenasalaService && (
                    <p className="text-danger">{isError.nenasalaService}</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    id="description"
                    placeholder="Description"
                    type="text"
                    value={isData.description}
                    name='description'
                    required
                    onChange={handleInputChange}
                  />
                  {isError.description && (
                    <p className="text-danger">{isError.description}</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    id="quantity"
                    placeholder="Quantity"
                    type="text"
                    value={isData.quantity}
                    name='quantity'
                    required
                    onChange={handleInputChange}
                  />
                  {isError.quantity && (
                    <p className="text-danger">{isError.quantity}</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    id="amount"
                    placeholder="Amount"
                    type="text"
                    value={isData.amount}
                    name='amount'
                    required
                    onChange={handleInputChange}
                  />
                  {isError.amount && (
                    <p className="text-danger">{isError.amount}</p>
                  )}
                </FormGroup>
                <Button
                  color="primary"
                  type="button"
                  onClick={insertNewPayment}
                  disabled={isInsertButtonDisabled}
                >
                  Insert
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
        {isLoadingServices ? (
          <Card>
            <Spinner className="m-10">Loading...</Spinner>
          </Card>
        ) : (
          <Card className="shadow" style={{ padding: "2rem" }}>
            <Row>
              <Col>
                <h3 className="mb-0">Supplier Orders</h3>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xl="5" lg="8" md="12" sm="12">
                <FormGroup>
                  <Dropdown
                    isOpen={filterServiceTypeDropdownOpen}
                    toggle={() => setFilterServiceTypeDropdownOpen(!filterServiceTypeDropdownOpen)}
                  >
                    <DropdownToggle caret>
                      {selectedtFilterServiceType?.name
                        ? selectedtFilterServiceType.name
                        : "Select a Supplier"}
                    </DropdownToggle>
                    <DropdownMenu style={{ maxHeight: '400px', overflowY: 'scroll' }}>
                      {allActiveServices &&
                        [...allActiveServices]
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((service) => (
                            <DropdownItem
                              key={service.name}
                              value={service.id}
                              onClick={() => {
                                setSelectedtFilterServiceType(service);
                                filterServicePaymentList(isDate ? selectedDate : selectedMonth, service.id);
                              }}
                            >
                              {service.name}
                            </DropdownItem>
                          ))}
                    </DropdownMenu>
                  </Dropdown>
                </FormGroup>
              </Col>
              <FormGroup className="mb-2 mr-2 ml-3">
                <Dropdown
                  isOpen={statusDropdownOpen}
                  toggle={() =>
                    setStatusDropdownOpen(!statusDropdownOpen)
                  }
                  className="filterButton"
                >
                  <DropdownToggle caret>
                    {selectedMonth ? selectedMonth : "Filter by Month"}
                  </DropdownToggle>
                  {months && months.length > 0 ? (
                    <DropdownMenu>
                      {months.map((month) => (
                        <DropdownItem
                          key={month.label}
                          value={month.label}
                          onClick={() =>
                            filterClick(
                              month.label
                            )
                          }
                        >
                          {month.label}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  ) : null}
                </Dropdown>
              </FormGroup>
              <FormGroup className="mb-2 ml-3">
                <DatePicker
                  showIcon
                  selected={selectedDate}
                  onChange={(date) => filterByDate(date, selectedtFilterServiceType?.id ? selectedtFilterServiceType.id : null)}
                  className="dateSelector"
                  icon="fa fa-calendar"
                  placeholderText="Filter by Date"
                  fixedHeight="47px"
                />
              </FormGroup>
              <FormGroup className="ml-3">
                <Button
                  color="primary"
                  type="button"
                  onClick={resetFilter}
                >
                  Reset
                </Button>
              </FormGroup>
            </Row>
            {!isServices ? (
              <Card className="text-center" style={{ padding: "1rem" }}>
                <img src={require("../../assets/img/brand/nodata.png")} className="noDataImage" />
              </Card>
            ) : (
              <>
                <Row>
                  <Col style={{ fontSize: '20px', fontWeight: 'bold' }}>
                    <Card className="mb-4 shadow" style={{ padding: "1rem", minWidth: '250px', maxWidth: '300px', backgroundColor: '#f7fafc' }}>
                      Total Amount: {totalAmount?.toFixed(2)}
                    </Card>
                  </Col>
                </Row>
                <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                  <Table className="align-items-center" responsive style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead className="thead-light">
                      <tr style={{ position: 'sticky', top: 0, backgroundColor: 'white' }}>
                        <th scope="col">Description</th>
                        <th scope="col">Supplier</th>
                        <th scope="col">Created By</th>
                        <th scope="col">Quantity</th>
                        <th scope="col" style={{ textAlign: "right" }}>Amount</th>
                      </tr>
                    </thead>
                    <tbody style={{ height: '200px' }}>
                      {allServices?.map((service, index) => (
                        <tr key={index}>
                          <td>{service.description}</td>
                          <td>{service.supplier.name}</td>
                          <td>{service.user.full_name}</td>
                          <td>{service.quantity}</td>
                          <td style={{ textAlign: "right" }}>{service.amount.toFixed(2)}</td>
                        </tr>
                      ))}
                      <tr style={{ position: 'sticky', bottom: 0, backgroundColor: 'white' }}>
                        <td colSpan={4} style={{ fontSize: '22px', fontWeight: 'bold' }}>Total Amount</td>
                        <td style={{ textAlign: "right", fontSize: '26px', fontWeight: 'bold' }}>{totalAmount.toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </>
            )}
            <div style={{ paddingTop: "10px" }}>
              {pageCount > 1 ?
                <Paginations totalPages={pageCount} handlePagination={handlePagination} currentPage={currentPage}
                  currentStart={currentStart}
                  setCurrentStart={setCurrentStart}></Paginations>
                : null}
            </div>
          </Card>
        )}
      </div>
    </>
  );
};

export default SupplierTransactions;
