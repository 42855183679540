import React, { useState, useEffect, useRef } from "react";
import { Card, CardHeader, Form, FormGroup, Row, Col, Input, Button } from "reactstrap";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import { getLastBarcode } from "actions/Barcode";
import generatePDF,{ usePDF, Margin } from 'react-to-pdf';
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';
import '../../assets/css/barcode-style.css';

// Function to generate an array of barcodes
const generateBarcodes = (finalBarCode, count) => {
    return Array.from({ length: count }, (_, index) => ({
        barcode: `${finalBarCode + index}`
    }));
};

const BarCodeGenerate = () => {
    // const targetRef = useRef();
    const [finalBarCode, setFinalBarCode] = useState('');
    const [count, setCount] = useState('');

    const fetchData = async () => {
        try {
            const data = await getLastBarcode();
            setFinalBarCode(data.barcode_number);
        } catch (error) {
            console.error("Error fetching barcode:", error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleCountChange = (e) => {
        setCount(e.target.value);
    }

    const barcodes = generateBarcodes(finalBarCode+1, count);

    // Group barcodes in pairs
    const groupedBarcodes = [];
    for (let i = 0; i < barcodes.length; i += 2) {
        groupedBarcodes.push(barcodes.slice(i, i + 2));
    }

    const { toPDF, targetRef } = usePDF({
        method: "save",
        filename: "usepdf-example.pdf",
        page: { margin:{
            top: Margin.NONE,
            right: Margin.NONE,
            bottom: 5,
            right: Margin.NONE
        } },
      });

    return (
        <>
            <SecondaryHeader />
            <div className="mt--5 container-fluid">
                <Card className="mb-4 shadow">
                    <CardHeader className="border-0" />
                    <Form className="ml-4 mb-4 mr-4">
                        <Row>
                            <Col md="5">
                                <FormGroup>
                                    <Input
                                        id="grade"
                                        placeholder="Grade"
                                        type="text"
                                        value={finalBarCode}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Input
                                        id="count"
                                        placeholder="Count"
                                        type="number"
                                        value={count}
                                        onChange={handleCountChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <FormGroup>
                                    <Button type="button" color="primary" onClick={toPDF}>Generate</Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                    {/* <div>
                    <BarCodeReport ref={targetRef}
                    finalBarCode={finalBarCode+1} count={count}></BarCodeReport>
                    </div> */}
                     <div className="receipt" ref={targetRef}>
            {groupedBarcodes.map((group, index) => (
                <div key={index} className="receiptPaper" style={{ breakInside: 'avoid' }}>
                    {group.map((item, idx) => (
                        <div key={idx} className="">
                            <div className='barcodeCard'>
                                <div className='qrRow'>
                                    <div className='studentDetails'>                                      
                                    <div>
                                        <h3 className="nenasala">nenasala Institute</h3>
                                    </div>
                                        <h5 className="stDetails">Grade:</h5>
                                        <h5 className="stDetails">Name:</h5>
                                    </div>
                                    <div>
                                        <QRCode value={item.barcode} size={113} className='qrCode'/>
                                    </div>
                                </div>
                                <div className="barcodeRow">
                                    <div>
                                        <Barcode value={item.barcode} width={3} height={80} className='barCode'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
                </Card>
            </div>
        </>
    );
}

export default BarCodeGenerate;
