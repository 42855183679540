// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import { useState, useEffect } from "react";
import { getUser } from "actions/Auth";

const UserHeader = () => {
  
  const [user, setUser] = useState(null);

  const fetchData = async () => {
    try {
      const user = await getUser();
      setUser(user);
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: "600px",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        <span className="mask bg-gradient-info opacity-8" />
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="12" md="10">
              <h1 className="display-2 text-nenasala">Hello {user? user.full_name: ''}</h1>
              <p className="text-nenasala mt-0 mb-5">
                Have a Nice Day!!!
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default UserHeader;
