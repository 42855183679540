import React from 'react';
import '../../assets/css/receipt-styles.css';
import Barcode from 'react-barcode';

function getMonthName(monthNumber) {
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Adjust for zero-based indexing if necessary
  const index = monthNumber - 1;

  // Check for valid month number
  if (index >= 0 && index < 12) {
    return monthNames[index];
  } else {
    return "Invalid month number";
  }
}

const StudentBulkPaymentReceipt = React.forwardRef((props, ref) => {
  const invoiceDate = props.allDetails.invoiceDate;
  const date = new Date(props.allDetails.invoiceDate);

  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  let formattedDate = date.toLocaleDateString('en-US', options);

  formattedDate = formattedDate.replace(',', '');
  const monthName = getMonthName(props.allDetails.month);

  return (
    <>
      <style>
        {`
      @media print {
          @page {
              size: 80mm auto !important;
              margin: 0;
          }
      }
  `}
      </style>
      <div className="payReceipt m-5" ref={ref}>
        <>
          {props.allDetails.payments.map((payment, index) => (
            <div key={index}>
              <div className='studentReceiptHeader'>
                <h3 style={{ fontSize: 38, color: 'black' }}>
                  {payment.subject} {payment.grade} {payment.year}
                </h3>
                <h3 style={{ fontSize: 38, color: 'black' }}>{payment.teacherName}</h3>
              </div>
              <div className='studentPayDetails'>
                <h2 style={{ fontSize: 36, color: 'black' }}>Invoice No: {payment.invoiceNo}</h2>
                <h2 style={{ fontSize: 36, color: 'black' }}>
                  Paid Month: {getMonthName(payment.month)} {/* If `monthName` is a function */}
                </h2>
                <h2 style={{ fontSize: 36, color: 'black' }}>
                  Paid Amount: {Number(payment.paidAmount).toFixed(2)}{" "}
                  {payment.payType === 'HALF_FREE' ? `(Half Card)` :
                    payment.payType === 'FULL_FREE' ? `(Free Card)` : null
                  }
                </h2>
              </div>
            </div>
          ))}
        </>
        <div className='studentReceiptBarcodeSection'>
          <h2 style={{ fontSize: 36 }}>{props.allDetails.studentName}</h2>
          <Barcode value={props.allDetails.barcode} width={3} height={80} className='studentReceiptBarCode' />
        </div>
        <hr style={{ height: 2, color: 'black' }} />
        <div className='studentReceiptInstSection'>
          <h2 style={{ fontSize: 32, color: 'black', fontWeight: 'bold' }}>Nenasala Balangoda</h2>
          <div>
            <p style={{ fontSize: 25, color: 'black', fontWeight: 'bold' }}>No 18/1, Kalthota Road, Balangoda</p>
            <p style={{ fontSize: 25, color: 'black', fontWeight: 'bold' }}>Tele: 0452289889 / 0452286646</p>
            <p style={{ fontSize: 25, color: 'black', fontWeight: 'bold' }}>WhatsApp: 0777775358</p>
          </div>
        </div>
      </div>
    </>
  );
});

export default StudentBulkPaymentReceipt;