// reactstrap components
import { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Modal,
} from "reactstrap";
import { getUser, signInSubmit } from "../../actions/Auth/index.js";
import { useNavigate } from "react-router-dom";
import "../../assets/css/styles.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const [isFailed, setFailed] = useState(false);

  const navigate = useNavigate();

  const getUserDetails = async () => {
    const user = await getUser();
  }

  const handleSignIn = async () => {
    try {
      const result = await signInSubmit({ email, password });
      if (result.success === true) {
        const user = await getUser();
        if (user.roleName === 'admin') {
          navigate("/admin/index");
        }
        else if(user.roleName === 'high-level-admin') {
          navigate("/high-level-admin/index");
        }
        else if (user.roleName === 'teacher') {
          navigate("/teacher/dashboard");
        }
        else if (user.roleName === 'card-marker') {
          navigate("/classassistant/student");
        }
      } else {
        setErrorMessage(result.message);
      }
    } catch (error) {
      setErrorMessage(error.response.data.message);
      setFailed(true);
    }
  };

  const goToHome = () => {
    navigate("/")
  }
  return (
    <>
      <Modal
        className="modal-dialog modal-danger"
        isOpen={isFailed}
        toggle={() => setFailed(false)}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            {errorMessage}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setFailed(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
      </Modal>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pt-5 border-bottom-0 loginHeader">
            <span>
              <img
                src={require("../../assets/img/brand/nenasala-logo.png")}
                width={200}
                alt="Ardillalabs IMS Logo"
                onClick={goToHome}
                style={{ cursor: "pointer" }}
              />
            </span>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="button"
                  onClick={handleSignIn}
                >
                  Sign In
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Login;
