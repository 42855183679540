import {
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Table,
  Card,
  CardHeader,
  Modal,
  Button,
  Spinner,
  CardBody,
} from "reactstrap";
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import Paginations from "components/Pagination/Paginations";
import { useParams } from "react-router-dom";
import { getExamLeaderBoard } from "actions/Exams";
import Chart from "chart.js";
import { Line } from "react-chartjs-2";
import {
  chartOptions,
  parseOptions,
} from "variables/charts.js";
import { getExamLeaderBoardGraph } from "actions/Exams";

const AdminExamResults = () => {

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const { teacherClassId, examId, maxMarks } = useParams();

  const [allExamResults, setAllExamResults] = useState(null);
  const [isExams, setIsExams] = useState(false);
  const [isLoadingExams, setLoadingExams] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPge, SetItemsPerPage] = useState(10);
  const [skip, setSkip] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentStart, setCurrentStart] = useState(0);
  const [examName, setExamName] = useState('');
  const [isExamName, setIsExamName] = useState(false);
  const [incomeGraphData, setIncomeGraphData] = useState(null);

  const leaderBoard = async () => {
    try {
      const response = await getExamLeaderBoardGraph(examId, maxMarks);
      setIncomeGraphData(response.flat());
    } catch (error) {
    }
  }

  const fetchData = async (itemsPerPge, skip) => {
    try {
      await leaderBoard();
      const leaderboard = await getExamLeaderBoard(teacherClassId, examId, skip, itemsPerPge);
     if (leaderboard.data.length > 0) {
        setIsExamName(true);
      } else {
        setIsExamName(false);
      }
      setPageCount(leaderboard.data.pageCount);
      setAllExamResults(leaderboard.data);
      setLoadingExams(false);
      setIsExams(true);
    } catch (error) {
      setLoadingExams(false);
      setIsExams(false);
    }
  };

  useEffect(() => {
    fetchData(itemsPerPge, skip);
  }, []);


  const handlePagination = async (pageNumber) => {
    await fetchData(itemsPerPge, pageNumber - 1);
    setCurrentPage(pageNumber);
  }

  const dataValues = incomeGraphData?.map(item => parseInt(item.count, 10));

  const labels = incomeGraphData?.map(item => {
    return (item.mark);
  });

  const lineGraphData = {
    labels: labels,
    datasets: [{
      label: 'Count',
      data: dataValues,
      fill: false,
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1
    }]
  };

  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        {isLoadingExams ? (
          <Card>
            <Spinner className="m-10" color="primary" size="sm">Loading...</Spinner>
          </Card>
        ) : !isExams ? (
          <Card className="text-center" style={{ padding: "1rem" }}>
            <img src={require("../../assets/img/brand/nodata.png")} className="noDataImage" />
          </Card>
        ) : (
          <Card className="shadow" style={{ padding: "2rem" }}>
            <Row>
              <Col>
                <h3 className="mb-3">{isExamName ? `${examName} Results` : null}</h3>
              </Col>
            </Row>
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Barcode</th>
                  <th scope="col">Marks</th>
                  <th scope="col">Position</th>
                </tr>
              </thead>
              <tbody>
                {allExamResults?.map((exam, index) => (
                  <tr key={index}
                  style={{
                    backgroundColor:
                      exam.position === '1' ? '#d4af37' :
                      exam.position === '2' ? '#c0c0c0' :
                      exam.position === '3' ? '#cd7f32' : 'transparent',
                      color: 'black'
                  }}>
                    <td>{exam.full_name || ''}</td>
                    <td>{exam.barcode || ''}</td>
                    <td>{exam.mark || ''}</td>
                    <td>{exam.position || ''}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div style={{ paddingTop: "10px" }}>
              {pageCount > 1 ?
                <Paginations totalPages={pageCount} handlePagination={handlePagination} currentPage={currentPage}
                  currentStart={currentStart}
                  setCurrentStart={setCurrentStart}></Paginations>
                : null}
            </div>
          </Card>
        )}
        <Card className="bg-gradient-default shadow mt-4">
          <CardHeader className="bg-transparent">
            <Row className="align-items-center">
              <div className="col">
                <h2 className="text-white mb-0">LeaderBoard Graph</h2>
              </div>
            </Row>
          </CardHeader>
          <CardBody>
            <div className="chart">
              <Line
                data={lineGraphData}
                options={{
                  responsive: true,
                  scales: {
                    yAxes: [{
                      ticks: {
                        beginAtZero: true,
                        min: 0,
                        stepSize: 20000,
                      }
                    }]
                  }
                }}
              />
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default AdminExamResults;
