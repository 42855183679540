import {
  FormGroup,
  Form,
  Input,
  InputGroup,
  Row,
  Col,
  Table,
  Card,
  CardHeader,
  Modal,
  Button,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from "reactstrap";
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import { addNewGrade } from "actions/Grades";
import { editGrade } from "actions/Grades";
import { getGradesBySearch } from "actions/Grades";
import Paginations from "components/Pagination/Paginations";
import { BiEdit } from "react-icons/bi";
import { getServiceByLimit } from "actions/Services";
import { addNewService } from "actions/Services";
import { editService } from "actions/Services";
import { getServiceBySearch } from "actions/Services";

const Service = () => {
  const [allServices, setAllServices] = useState(null);
  const [isServices, setServices] = useState(false);
  const [isLoadingServices, setLoadingServices] = useState(true);
  const [editModal, setEditmodal] = useState(false);
  const [selectedService, setSelectedService] = useState({
    id: null,
    name: "",
    status: "",
  });
  const [isData, setData] = useState({
    name: "",
    status: "",
  });
  const [errors, setErrors] = useState({});
  const [editErrors, setEditErrors] = useState({
    name: ""
  });
  const [isSuccess, setSuccess] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [isEdited, setEdit] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isSuccessMessage, setSuccessMessage] = useState("");
  const [isErrorMessage, setErrorMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPge, setItemsPerPage] = useState(10);
  const [skip, setSkip] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentStart, setCurrentStart] = useState(0);
  const [disabledInsertBtn, setDisabledInsertBtn] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [initialService, setInitialService] = useState({
    id: null,
    name: "",
    status: "",
  });

  const fetchData = async (itemsPerPge, skip) => {
    try {
      const Grades = await getServiceByLimit(itemsPerPge, skip);
      setPageCount(Grades.data.pageCount);
      setAllServices(Grades.data.data);
      setLoadingServices(false);
      setServices(true);
    } catch (error) {
      setLoadingServices(false);
      setServices(false);
    }
  };

  useEffect(() => {
    fetchData(itemsPerPge, skip);
  }, []);

  const searchService = async (searchQuery) => {
    const searched = await getServiceBySearch(itemsPerPge, skip, searchQuery);
    setPageCount(searched.data.pageCount);
    setAllServices(searched.data.data);
  };

  const filterBySearch = async (itemsPerPge, skip, searchQuery) => {
    const searched = await getServiceBySearch(itemsPerPge, skip, searchQuery);
    setPageCount(searched.data.pageCount);
    setAllServices(searched.data.data);
    setCurrentPage(skip + 1);
  }

  const handlePagination = async (pageNumber) => {
    if (searchQuery !== " ") {
      await filterBySearch(itemsPerPge, pageNumber - 1, searchQuery);
    } else {
      const features = await fetchData(itemsPerPge, pageNumber - 1);
      setCurrentPage(pageNumber);
    }
  }


  const handleInputChange = (values) => {
    setErrors({ name: "" });
    setData({
      ...isData,
      ...values,
    });
  };

  const isValidService = (value) => {
    if (value.length < 1) {
      setErrors({
        name: "Name must be at least one character long.",
      });
      return false;
    } else {
      setErrors({ name: "" });
      return true;
    }
  };

  const insertNewService = async () => {
    try {
      if (isValidService(isData.name)) {
        setDisabledInsertBtn(true);
        const response = await addNewService(isData.name);
        if (response.success === true) {
          setSuccessMessage(response.message);
          setSuccess(true);
          setData({
            name: "",
          });
          fetchData(itemsPerPge, skip);
          setDisabledInsertBtn(false);
        } else {
          setErrorMessage(response.message);
          setFailed(true);
          setData({
            gradeName: "",
          });
          setDisabledInsertBtn(false);
        }
      }
    } catch (error) {
      setErrorMessage(error.message);
      setFailed(true);
      setDisabledInsertBtn(false);
    }
  };

  const handleEditClick = (service) => {
    setSelectedService(service);
    setInitialService(service);
    setEditmodal(true);
  };

  const isValidEditedService = (value) => {
    if (value.name.length < 1) {
      setEditErrors({
        name: "Name must be at least one character long.",
      });
      return false;
    } else {
      setEditErrors({ name: "" });
      return true;
    }
  };

  function getChangedValues(selectedService) {
    const changedValues = {};
    for (const key in selectedService) {
      if (
        key !== "id" &&
        selectedService.hasOwnProperty(key) &&
        selectedService[key] !== initialService[key]
      ) {
        changedValues[key] = selectedService[key];
      }

      if (key === "id") {
        changedValues[key] = selectedService[key];
      }
    }
    return changedValues;
  }

  const editServiceDetails = async () => {
    try {
      const validityCheck = isValidEditedService(selectedService);
      if (validityCheck === true) {
        setEdit(false);
        const data = getChangedValues(selectedService);
        const body = JSON.stringify(data);
        const response = await editService(body);
        if (response.success === true) {
          setSuccessMessage(response.message);
          setSuccess(true);
          setData({
            name: "",
          });
          fetchData(itemsPerPge, skip);
          setEditmodal(false);
        } else {
          setFailed(true);
        }
      }
    } catch (error) {
      setErrorMessage(error.message);
      setFailed(true);
      setEdit(true);
    }
  };

  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        <Modal
          className="modal-dialog-centered modal-success"
          isOpen={isSuccess}
          toggle={() => setSuccess(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isSuccessMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setSuccess(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-danger"
          isOpen={isFailed}
          toggle={() => setFailed(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isErrorMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setFailed(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Card className="mb-4 shadow">
          <CardHeader className="border-0">
            <Row>
              <Col md="6" xs="12">
                <span>
                  <h3 className="mb-0">Add New Nenasala Service</h3>
                </span>
              </Col>
            </Row>
          </CardHeader>
          <Form className="ml-4 mb-4 mr-4">
            <Row>
              <Col md="6">
                <FormGroup>
                  <Input
                    id="name"
                    placeholder="Service Name"
                    type="text"
                    value={isData.name}
                    required
                    onChange={(e) =>
                      handleInputChange({ name: e.target.value })
                    }
                  />
                  {errors.name && (
                    <p className="text-danger">{errors.name}</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Button
                  color="primary"
                  type="button"
                  onClick={() => insertNewService()}
                  disabled={disabledInsertBtn}
                >
                  Insert
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
        {isLoadingServices ? (
          <Card>
            <Spinner className="m-10">Loading...</Spinner>
          </Card>
        ) : !isServices ? (
          <Card className="text-center" style={{ padding: "1rem" }}>
            <img src={require("../../assets/img/brand/nodata.png")} className="noDataImage" />
          </Card>
        ) : (
          <Card className="shadow" style={{ padding: "2rem" }}>
            <Row>
              <Col>
                <h3 className="mb-0">Nenasala Services</h3>
              </Col>
              <Col className="justify-content-end" md="4">
                <InputGroup className="mb-3">
                  <Input
                    type="text"
                    placeholder="Search by service name"
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                      setCurrentPage(1);
                      setCurrentStart(0);
                      searchService(e.target.value);
                    }}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {allServices?.map((service, index) => (
                  <tr key={index}>
                    <td>{service.name}</td>
                    <td>{service.status ? "Active" : "Inactive"}</td>
                    <td>
                      <Button
                        color="primary"
                        type="button"
                        id="editGrade"
                        onClick={() => handleEditClick(service)}
                      >
                        <BiEdit />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div style={{ paddingTop: "10px" }}>
              {pageCount > 1 ?
                <Paginations totalPages={pageCount} handlePagination={handlePagination} currentPage={currentPage}
                  currentStart={currentStart}
                  setCurrentStart={setCurrentStart}></Paginations>
                : null}
            </div>
          </Card>
        )}
        <Modal
          className="modal-dialog-centered"
          isOpen={editModal}
          toggle={() => {
            setEditmodal(false);
            setEditErrors({ name: "" });
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Edit Nenasala Service
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setEditmodal(false);
                setEditErrors({ name: "" });
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <ModalBody>
            <Form>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Input
                      id="exampleFormControlInput1"
                      placeholder="Nenasala Service Name"
                      type="text"
                      value={selectedService ? selectedService.name : ""}
                      onChange={(e) => {
                        setSelectedService({
                          id: selectedService.id,
                          name: e.target.value,
                          status: selectedService.status,
                        });
                        setEdit(true);
                        setEditErrors({ name: "" })
                      }}
                    />
                    {editErrors.name && (
                      <p className="text-danger">{editErrors.name}</p>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                      <DropdownToggle caret>
                        {selectedService
                          ? selectedService.status
                            ? "Active"
                            : "Inactive"
                          : "Select Status"}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={(e) => {
                            setSelectedService({
                              id: selectedService.id,
                              name: selectedService.name,
                              status: true,
                            });
                            setEdit(true);
                          }}
                        >
                          Active
                        </DropdownItem>
                        <DropdownItem
                          onClick={(e) => {
                            setSelectedService({
                              id: selectedService.id,
                              name: selectedService.name,
                              status: false,
                            });
                            setEdit(true);
                          }}
                        >
                          Inactive
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Button
                  color="primary"
                  type="button"
                  disabled={!isEdited}
                  onClick={() => editServiceDetails()}
                  style={{ marginLeft: "15px" }}
                >
                  Save
                </Button>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default Service;
