import React, { useState, useEffect, useRef } from "react";
import {
    Card, CardHeader, Form, Row, Col, FormGroup, Input, Button, Table, Modal,
    Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
    Label,
    Alert
} from "reactstrap";
import { updateStudentByClassAssistant, getStudentByClassAssistants } from "actions/ClassAssistantLayout";
import { createStudentByClassAssistant } from "actions/ClassAssistantLayout";
import TemporaryReceipt from "components/Reports/TemporaryReceipt";
import '../../../assets/css/temporary-card-styles.css';
import { filterStudentListByAssistant } from "actions/ClassAssistantLayout";
import '../../../assets/css/profile-card.css';
import { editProfileImage } from "actions/ClassAssistantLayout";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getYear, getMonth } from 'date-fns';
import { createStudent } from "actions/Students";
import ProfileImage from "../../../assets/img/brand/blank_profile_pic.png";

const ClassAssistantStudent = () => {
    const range = (start, end, step) => {
        let output = [];
        for (let i = start; i < end; i += step) {
            output.push(i);
        }
        return output;
    }

    const years = range(1990, getYear(new Date()) + 1, 1);
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const receiptRef = useRef();
    const [isData, setData] = useState({});
    const [isError, setError] = useState({});
    const [allClasses, setAllClasses] = useState(null);
    const barcodeInputRef = useRef(null);
    const [barcodeInput, setBarcodeInput] = useState('');
    const [barcodeOnly, setBarcodeOnly] = useState(false);
    const [isReceivedData, setReceivedData] = useState(false);
    const [visible, setVisible] = useState(false);
    const [birthDate, setBirthDate] = useState(new Date());
    const [selectedType, setSelectedType] = useState('');

    const onDismiss = () => setVisible(false);

    const handleInputChange = (event) => {
        setError({ ...isError, [event.target.name]: "" });
        if (event.target.name === 'admission_fee') {
            setData({ ...isData, [event.target.name]: Number(event.target.value) });
        } else {
            setData({ ...isData, [event.target.name]: event.target.value });
        }
    };

    const getStudent = async (e) => {
        setReceivedData(false);
        let barcode = e.target.value;
        if (barcode.length <= 7) {
            setBarcodeInput(e.target.value);
        } else
            if (barcode.length > 7) {
                barcode = barcode.slice(7);
                setBarcodeInput(barcode);
            }
        handleInputChange(e);
        try {
            if (barcode.length === 7) {
                const response = await getStudentByClassAssistants(barcode);
                if (response.success === true) {
                    setData(response.students);
                    setSelectedType(response.students.user_type);
                    setBirthDate(response.students.dob);
                    setAllClasses(response.students.class_users);
                    setReceivedData(true);
                    setBarcodeOnly(false);
                } if (response.success === false) {
                    setData({ barcode: barcode });
                    setBarcodeOnly(true);
                    setAllClasses(null);
                }
            }
        } catch (error) {
            setData({});
            setAllClasses(null);
        }
    };

    const resetStudent = () => {
        setReceivedData(false);
        setData({});
        setAllClasses(null);
        setBarcodeInput('');
        if (barcodeInputRef.current) {
            barcodeInputRef.current.focus();
        }
    };

    const isValidStudent = (value) => {
        const errors = {};
        if (value.full_name.length < 3) {
            errors.full_name =
                "Student Full Name must be at least 3 characters long.";
        }
        if ((value.phone_number !== undefined && value.phone_number !== null)) {
            if (value.phone_number !== "") {
                if ((value.phone_number.length !== 10)) {
                    errors.phone_number = "Value must be a valid phone number.";
                }
            }
        }
        if ((value.land_phone_number !== undefined && value.land_phone_number !== null)) {
            if (value.land_phone_number !== "") {
                if ((value.land_phone_number.length !== 10)) {
                    errors.land_phone_number = "Value must be a valid phone number.";
                }
            }
        }
        if ((value.whatsapp_number !== undefined && value.whatsapp_number !== null)) {
            if (value.whatsapp_number !== "") {
                if ((value.whatsapp_number.length !== 10)) {
                    errors.whatsapp_number = "Value must be a valid phone number.";
                }
            }
        }
        if (Object.keys(errors).length > 0) {
            setError(errors);
            return false;
        } else {
            return true;
        }
    };

    const insertNewStudent = async () => {
        try {
            if (isData.id === undefined) {
                if (isValidStudent(isData)) {
                    const response = await createStudent(isData);
                    if (response.status === 201) {
                        setData(response.data.student);
                        setVisible(true);
                        setAllClasses(response.data.student.class_users);
                        setBarcodeInput(response.data.student.barcode);
                        setReceivedData(true);
                        setBarcodeOnly(false);
                    } if (response.success === false) {
                        setBarcodeOnly(true);
                        setAllClasses(null);
                    }
                }
            } else {
                if (isValidStudent(isData)) {
                    const response = await updateStudentByClassAssistant(isData);
                    if (response.success === true) {
                        setSuccess(true);
                        setSuccessMessage(response.message);
                    } else {
                        setFailed(true);
                        setErrorMessage('Update Student information is failed. Try Again!');
                    }
                }
            }
        } catch (error) {
            setFailed(true);
            setErrorMessage('Update Student information is failed. Try Again!');
        }
    };

    let barcodeString = '';
    const handleKeyDown = async (event) => {
        const barcodePattern = /^[0-9]$/;
        if (barcodePattern.test(event.key)) {
            barcodeString += event.key;
            if (barcodeString.length === 7) {
                barcodeInputRef.current.value = barcodeString;
            }
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const [searchItem, setSearchItem] = useState('')
    const [filteredUsers, setFilteredUsers] = useState(null)

    const handleInputChange1 = async (e) => {
        const searchTerm = e.target.value;
        setSearchItem(searchTerm);
        if (searchTerm.length >= 3) {
            const response = await filterStudentListByAssistant(searchTerm);
            const filteredItems = response.data.data.filter((user) =>
                user.full_name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredUsers(filteredItems);
        }
    }

    const getStudentBySeacrh = async (student) => {
        try {
            resetStudent();
            const response = await getStudentByClassAssistants(student);
            if (response.success === true) {
                setFilteredUsers(null);
                setSearchItem('')
                setBarcodeInput(student);
                setReceivedData(true);
                setData(response.students);
                setAllClasses(response.students.class_users);
                setBarcodeOnly(false);
            } if (response.success === false) {
                setBarcodeOnly(true);
                setAllClasses(null);
            }
        } catch (error) {

        }
    }

    const [searchbar, setSearchBar] = useState(false);

    const [editImage, setEditImage] = useState(null);
    const [previewEditThumbnailImage, setPreviewEditThumbnailImage] = useState(null);
    const [isSuccessMessage, setSuccessMessage] = useState("");
    const [isSuccess, setSuccess] = useState(false);
    const [isFailed, setFailed] = useState(false);
    const [isErrorMessage, setErrorMessage] = useState("");
    const inputImgRef = useRef(null);

    const handleEditImageChange = (event) => {
        const file = event.target.files[0];
        setEditImage({ image: file });
        const reader = new FileReader();
        reader.onload = (e) => {
            setPreviewEditThumbnailImage(e.target.result);
        };
        reader.readAsDataURL(file);
    };

    const addProfileImage = async () => {
        try {
            const response = await editProfileImage(editImage, isData.id);
            if (response.success === true) {
                setSuccessMessage(response.message);
                setSuccess(true);
            } else {
                setErrorMessage(response.message);
                setFailed(true);
            }
        } catch (error) {
            setErrorMessage(error.message);
            setFailed(true);
        }
    }

    const setDateOfBirth = async (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const newDate = year + "-" + month + "-" + day;
        setBirthDate(newDate);
        setData((prevData) => ({ ...prevData, dob: newDate }))
    }

    const handleTypeChange = (type) => {
        setSelectedType(type)
      }

    return (
        <>
            <div className="mt--3 container-fluid assistant-container">
                <Modal
                    className="modal-dialog-centered modal-success"
                    isOpen={isSuccess}
                    toggle={() => setSuccess(false)}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {isSuccessMessage}
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setSuccess(false)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                </Modal>
                <Modal
                    className="modal-dialog modal-danger"
                    isOpen={isFailed}
                    toggle={() => setFailed(false)}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {isErrorMessage}
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setFailed(false)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                </Modal>
                <Card className="mt-4 mb-4 shadow">
                    <CardHeader className="border-0 assistantStudentHeader">
                        <div className="headerLeft" style={{ display: 'flex', alignItems: 'center' }}>
                            <h3 className="mb-0 mr-2">Student</h3>
                            <i class="fa-solid fa-magnifying-glass" style={{ cursor: 'pointer' }} onClick={() => setSearchBar(!searchbar)}></i>
                        </div>
                        <div className="headerRight">
                            <Button type="button" onClick={insertNewStudent} disabled={!isData.full_name} color="primary">Save</Button>
                            <Button type="button" onClick={resetStudent} color="default">Reset</Button>
                        </div>
                    </CardHeader>
                    <Form className="ml-4 mb-4 mr-4">
                        <Row className="studentInsertUpper">
                            <Col md="12" lg="8">
                                {searchbar ?
                                    <>
                                        <Row>
                                            <Col sm="6">
                                                <FormGroup>
                                                    <Input
                                                        type="text"
                                                        value={searchItem}
                                                        onChange={handleInputChange1}
                                                        placeholder="Search..."
                                                        style={{ borderBottomLeftRadius: searchItem !== '' ? '0px' : '', borderBottomRightRadius: searchItem !== '' ? '0px' : '' }}
                                                    />
                                                    {searchItem && (
                                                        <ul style={{ position: 'absolute', width: '94%', listStyleType: 'none', padding: '0', border: '1px solid #ddd', zIndex: 10, backgroundColor: 'white', display: filteredUsers === null ? 'none' : '' }}>
                                                            {filteredUsers?.map((option, index) => (
                                                                <li key={index} style={{ padding: '8px', borderBottom: '1px solid #ddd', cursor: 'pointer' }} onClick={() => getStudentBySeacrh(option.barcode)}>
                                                                    {option.full_name}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm="6" md="6" lg="6">
                                                <FormGroup>
                                                    <Input
                                                        id="barcode"
                                                        placeholder="Barcode"
                                                        type="text"
                                                        value={barcodeInput}
                                                        onChange={getStudent}
                                                        name="barcode"
                                                        ref={barcodeInputRef}
                                                        autoFocus
                                                    />
                                                    {isError.barcode && (
                                                        <p className="text-danger">{isError.barcode}</p>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col sm="6" md="6" lg="6">
                                                <FormGroup>
                                                    <Input
                                                        id="registration_number"
                                                        placeholder="Registration Number"
                                                        type="text"
                                                        value={isData.registration_number || ''}
                                                        onChange={handleInputChange}
                                                        name="registration_number"
                                                    />
                                                    {isError.registration_number && (
                                                        <p className="text-danger">{isError.registration_number}</p>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </>
                                    : null}
                                <Alert color="default" isOpen={visible} toggle={onDismiss}>
                                    Barcode: {isData?.barcode}
                                    <br />
                                    Registration Number: {isData?.registration_number}
                                </Alert>
                                <FormGroup>
                                    <Input
                                        id="full_name"
                                        placeholder="Full Name"
                                        type="text"
                                        value={isData.full_name || ''}
                                        onChange={handleInputChange}
                                        name="full_name"
                                    />
                                    {isError.full_name && (
                                        <p className="text-danger">{isError.full_name}</p>
                                    )}
                                </FormGroup>
                                <Row>
                                    <Col md="12" lg="4" xl="4">
                                        <FormGroup>
                                            <DatePicker
                                                renderCustomHeader={({
                                                    date,
                                                    changeYear,
                                                    changeMonth,
                                                }) => (
                                                    <div
                                                        style={{
                                                            margin: 10,
                                                            display: "flex",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <select
                                                            value={getYear(date)}
                                                            onChange={({ target: { value } }) => changeYear(value)}
                                                        >
                                                            {years.map((option) => (
                                                                <option key={option} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>

                                                        <select
                                                            value={months[getMonth(date)]}
                                                            onChange={({ target: { value } }) =>
                                                                changeMonth(months.indexOf(value))
                                                            }
                                                        >
                                                            {months.map((option) => (
                                                                <option key={option} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                )}
                                                showIcon
                                                selected={birthDate}
                                                onChange={(date) => {
                                                    setDateOfBirth(date);
                                                    setError({ ...isError, dob: '' })
                                                }}
                                                className="dateSelector"
                                                icon="fa fa-calendar"
                                            />

                                            {isError.dob && (
                                                <p className="text-danger">{isError.dob}</p>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="12" lg="8" xl="8">
                                        <FormGroup>
                                            <Input
                                                id="phone_number"
                                                placeholder="Phone Number"
                                                type="text"
                                                value={isData.phone_number || ''}
                                                onChange={handleInputChange}
                                                name="phone_number"
                                            />
                                            {isError.phone_number && (
                                                <p className="text-danger">{isError.phone_number}</p>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup tag="fieldset" className="fieldSetPayOptions">
                                            <div className="radioOptions">
                                                <FormGroup check>
                                                    <Input
                                                        id="inRadio"
                                                        name="typeRadio"
                                                        type="radio"
                                                        checked={selectedType === 'IT_SCHOOL'}
                                                        onChange={() => {
                                                            handleTypeChange('IT_SCHOOL');
                                                            setData((prevData) => ({ ...prevData, user_type: 'IT_SCHOOL' }));
                                                            setError({ ...isError, course_type: '' })
                                                        }}
                                                        style={{ transform: 'scale(1.5)' }}
                                                    />
                                                    {' '}
                                                    <Label check for="inRadio" className="ml-2 mr-4" style={{ fontSize: "18px" }}>
                                                        IT School
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Input
                                                        id="outRadio"
                                                        name="typeRadio"
                                                        type="radio"
                                                        checked={selectedType === 'VOCATIONAL_TRAINING'}
                                                        onChange={() => {
                                                            handleTypeChange('VOCATIONAL_TRAINING');
                                                            setData((prevData) => ({ ...prevData, user_type: 'VOCATIONAL_TRAINING' }));
                                                            setError({ ...isError, course_type: '' })
                                                        }}
                                                        style={{ transform: 'scale(1.5)' }}
                                                    />
                                                    {' '}
                                                    <Label check for="outRadio" className="ml-2" style={{ fontSize: "18px" }} >
                                                        Vocational Training
                                                    </Label>
                                                </FormGroup>
                                                {isError.course_type && (
                                                    <p className="text-danger">{isError.course_type}</p>
                                                )}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Label style={{ fontWeight: 'bold', marginTop: '20px' }}>Guardian Information</Label> <br />
                                <Label style={{ fontWeight: 'bold' }}>Mother</Label>
                                <FormGroup>
                                    <Input
                                        id="m_name"
                                        placeholder="Mother's Name"
                                        type="text"
                                        value={isData.m_name || ''}
                                        onChange={handleInputChange}
                                        name="m_name"
                                    />
                                    {isError.m_name && (
                                        <p className="text-danger">{isError.m_name}</p>
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        id="m_phone_number"
                                        placeholder="Mother's Phone Number"
                                        type="text"
                                        value={isData.m_phone_number || ''}
                                        onChange={handleInputChange}
                                        name="m_phone_number"
                                    />
                                    {isError.m_phone_number && (
                                        <p className="text-danger">{isError.m_phone_number}</p>
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        id="m_whatsapp_number"
                                        placeholder="Mother's WhatsApp Number"
                                        type="text"
                                        value={isData.m_whatsapp_number || ''}
                                        onChange={handleInputChange}
                                        name="m_whatsapp_number"
                                    />
                                    {isError.m_whatsapp_number && (
                                        <p className="text-danger">{isError.m_whatsapp_number}</p>
                                    )}
                                </FormGroup>
                                <Label style={{ fontWeight: 'bold' }}>Father</Label>
                                <FormGroup>
                                    <Input
                                        id="f_name"
                                        placeholder="Father's Name"
                                        type="text"
                                        value={isData.f_name || ''}
                                        onChange={handleInputChange}
                                        name="f_name"
                                    />
                                    {isError.f_name && (
                                        <p className="text-danger">{isError.f_name}</p>
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        id="f_phone_number"
                                        placeholder="Father's Phone Number"
                                        type="text"
                                        value={isData.f_phone_number || ''}
                                        onChange={handleInputChange}
                                        name="f_phone_number"
                                    />
                                    {isError.f_phone_number && (
                                        <p className="text-danger">{isError.f_phone_number}</p>
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        id="f_whatsapp_number"
                                        placeholder="Father's WhatsApp Number"
                                        type="text"
                                        value={isData.f_whatsapp_number || ''}
                                        onChange={handleInputChange}
                                        name="f_whatsapp_number"
                                    />
                                    {isError.f_whatsapp_number && (
                                        <p className="text-danger">{isError.f_whatsapp_number}</p>
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        id="remark"
                                        placeholder="Remark"
                                        type="text"
                                        value={isData.remark || ''}
                                        onChange={handleInputChange}
                                        name="remark"
                                    />
                                    {isError.remark && (
                                        <p className="text-danger">{isError.remark}</p>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col md="12" lg="4" className="assistantStuImage">
                                {isReceivedData === true ?
                                    <div className="profile-card">
                                        <div className="profile-card-header">
                                            <i className="icon-back"></i>
                                            <i className="icon-menu"></i>
                                        </div>
                                        <div className="profile-img">
                                            <FormGroup>
                                                <div className="studentImgPrv">
                                                    <img
                                                        src={
                                                            previewEditThumbnailImage
                                                                ? previewEditThumbnailImage
                                                                : isData?.image_url ? isData.image_url : ProfileImage
                                                        }
                                                        alt="Selected or Default Preview"
                                                        style={{
                                                            width: "200px",
                                                            height: "100%",
                                                            objectFit: "cover",
                                                            border: "1px solid #ccc",
                                                            borderRadius: "8px"
                                                        }}
                                                    />
                                                    <input
                                                        id="thumbnailImage"
                                                        type="file"
                                                        name="image"
                                                        accept="image/*"
                                                        ref={inputImgRef}
                                                        onChange={handleEditImageChange}
                                                        style={{
                                                            position: "absolute",
                                                            top: 0,
                                                            left: 0,
                                                            width: "100%",
                                                            height: "100%",
                                                            opacity: 0,
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                </div>
                                                <Button
                                                    color="primary"
                                                    type="button"
                                                    onClick={() => {
                                                        addProfileImage();
                                                    }}
                                                    style={{
                                                        width: "200px",
                                                    }}
                                                    className="imageUploadBtn"
                                                >
                                                    Upload
                                                </Button>
                                            </FormGroup>
                                        </div>
                                    </div>
                                    : null}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12" md="12" lg="8">
                                {allClasses?.length > 0 ?
                                    <Table className="align-items-center" responsive>
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Teacher</th>
                                                <th scope="col">Subject</th>
                                                <th scope="col">Grade</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allClasses?.map((classUser, index) => (
                                                <tr key={index}>
                                                    <td>{classUser.teacher_class.teacher.full_name}</td>
                                                    <td>{classUser.teacher_class.subject.name}</td>
                                                    <td>{classUser.teacher_class.grade.name}</td>
                                                </tr>))}
                                        </tbody>
                                    </Table> : null}
                            </Col>
                        </Row>
                    </Form>
                </Card>
                <div style={{ display: "none" }}>
                    <TemporaryReceipt ref={receiptRef} allDetails={[isData.barcode, isData.full_name]} />
                </div>
            </div>
        </>
    );
};

export default ClassAssistantStudent;