import {
  Table,
  Card,
  CardHeader,
  Modal,
  Button,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Spinner,
  Label,
} from "reactstrap";
import { useState, useEffect, useRef } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import { Link, useParams } from "react-router-dom";
import { getAllTeachers } from "actions/Teachers";
import { getAllSubjects } from "actions/Subjects";
import { getAllGrades } from "actions/Grades";
import { editTeacherClassDetails } from "actions/Teachers";
import { findClassesByTeacherIdForAdmin } from "actions/Teachers";
import { BiEdit } from "react-icons/bi";
import { HiDownload } from "react-icons/hi";
import { downloadMonthlyIncomeReport } from "actions/Teachers";

const ClassTeacherDetails = () => {
  const { id } = useParams();
  const [teacherClasses, setTeacherClasses] = useState(null);
  const [isTeacherClasses, setIsTeacherClasses] = useState(false);

  const [isLoadingStudents, setLoadingStudents] = useState(true);
  const [today, setToday] = useState(null);
  const [todayDate, setTodayDate] = useState(null);
  const [previousMonth, setPreviousMonth] = useState(null);
  const [allSubjets, setAllSubjects] = useState(null);
  const [allGrades, setAllGrades] = useState(null);
  const [allTeachers, setAllTeachers] = useState(null);

  const fetchData = async () => {
    try {
      const teacherClasses = await findClassesByTeacherIdForAdmin(id);
      setTeacherClasses(teacherClasses);
      const teachers = await getAllTeachers();
      setAllTeachers(teachers);
      const subjects = await getAllSubjects();
      setAllSubjects(subjects);
      const grades = await getAllGrades();
      setAllGrades(grades);
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1; // Month is zero-indexed
      const day = currentDate.getDate();
      setToday({
        year: year,
        month: month,
        day: day,
      });
      setTodayDate({
        year: year,
        month: month.toString().padStart(2, "0"),
        day: day.toString().padStart(2, "0"),
      });
      currentDate.setMonth(currentDate.getMonth() - 1);
      const yearPrevious = currentDate.getFullYear();
      const monthPrevious = String(currentDate.getMonth() + 1).padStart(2, '0');
      setPreviousMonth({
        year: yearPrevious,
        month: monthPrevious
      });
      setLoadingStudents(false);
      if (teacherClasses.length > 0) {
        setIsTeacherClasses(true);
      }
    } catch (error) {
      setLoadingStudents(false);
      setIsTeacherClasses(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        {isLoadingStudents ? (
          <Card style={{}}>
            <Spinner className="m-10">Loading...</Spinner>
          </Card>
        ) : !isTeacherClasses ? (
          <Card className="text-center" style={{ padding: "1rem" }}>
            <img src={require("../../../assets/img/brand/nodata.png")} className="noDataImage" />
          </Card>
        ) : (
          <Card className="shadow" style={{ padding: "1rem" }}>
            <CardHeader className="border-0">
              <Row className="headerSection">
                <div className="teacherClassTitle">
                  <h3 className="mb-0">Classes of {teacherClasses[0]?.teacher?.full_name}</h3>
                </div>
              </Row>
            </CardHeader>
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Grade</th>
                  <th scope="col">Subject</th>
                  <th scope="col">Class Fee</th>
                  <th scope="col">Status</th>
                  <th scope="col">Payment Type</th>
                  <th colSpan={4} style={{ textAlign: "center" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {teacherClasses?.map((teacherClass, index) => (
                  <tr key={index}>
                    <td>{teacherClass.grade.name}</td>
                    <td>{teacherClass.subject.name}</td>
                    <td>{teacherClass.price}</td>
                    <td>{teacherClass.status ? "Active" : "Inactive"}</td>
                    <td>{teacherClass.payment_type}</td>
                    <td style={{ textAlign: "center" }}>
                      <Button
                        color="secondary"
                        type="button"
                        tag={Link}
                        to={`/high-level-admin/teachers/classStudents/${teacherClass.id}`}
                      >
                        Students
                      </Button>
                      {teacherClass.payment_type === "MONTHLY"?
                       <Button
                       color="secondary"
                       type="button"
                       tag={Link}
                       to={`/high-level-admin/teachers/payment-records/${teacherClass.id}/${todayDate.year}-${todayDate.month}`}
                     >
                       Payment Records
                     </Button>:
                      <Button
                      color="secondary"
                      type="button"
                      tag={Link}
                      to={`/high-level-admin/teachers/daily-payment-records/${teacherClass.id}/${todayDate.year}-${todayDate.month}-${todayDate.day}`}
                    >
                      Payment Records
                    </Button>
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        )}
      </div>
    </>
  );
};

export default ClassTeacherDetails;
