// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { useState, useEffect } from "react";
import { getUser } from "actions/Auth";
import { getThisMonthPaidAllStudentCountbyTeacher } from "actions/Dashboard";
import { getAllStudentCountByTeacher } from "actions/Dashboard";
import { getAllClassCountByTeacher } from "actions/Dashboard";

const TeacherHeader = () => {

  const [thisMonthPaidStudentCount, setThisMonthPaidStudentCount] = useState(null);
  const [previousMonthPaidStudentCount, setPreviousMonthPaidStudentCount] = useState(null);
  const [classCount, setClassCount] = useState(null);
  const [studentCount, setStudentCount] = useState(null);
  const [loadingData, setLoadingData]= useState(true);
  const [today, setToday] = useState(null);

  const fetchData = async () => {
    try {
        const user = await getUser();
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1; // Month is zero-indexed
      const day = currentDate.getDate();
      setToday({
        year: year,
        month: month,
        day: day,
      });
      const thisMonthPaidCount = await getThisMonthPaidAllStudentCountbyTeacher(year, month, user.id);
      setThisMonthPaidStudentCount(thisMonthPaidCount.total);
      const previousMonthPaidCount = await getThisMonthPaidAllStudentCountbyTeacher(year, month-1, user.id);
      setPreviousMonthPaidStudentCount(previousMonthPaidCount.total);
      const allClass = await getAllClassCountByTeacher(user.id);
      setClassCount(allClass.total);
      const allStudentCount = await getAllStudentCountByTeacher(user.id);
      setStudentCount(allStudentCount.total)
      setLoadingData(false);
    } catch (error) {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          This Month Payments
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {thisMonthPaidStudentCount}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-nenasala-secondary text-white rounded-circle shadow">
                          <i className="fas fa-chart-bar" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Last Month Payments
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {previousMonthPaidStudentCount}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-nenasala-secondary text-white rounded-circle shadow">
                          <i className="fas fa-chart-pie" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          All Student Count
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {studentCount}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-nenasala-secondary text-white rounded-circle shadow">
                          <i className="fas fa-users" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          All Class Count
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {classCount}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-nenasala-secondary text-white rounded-circle shadow">
                          <i className="fa fa-address-book" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default TeacherHeader;
