import axios from "axios";
const apiUrl = "https://nenasala-balangoda.ardillalabs.cloud/api";

export const getAllServices = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/nenasala-service`,
        config
      );

      if (response.status === 200) {
        resolve(response.data.data);
      } else {
        reject(response.data.data);
      }
    } catch (err) {
        reject(err);
    }
  });
};

export const addNewService = async (addFormData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };
      const body = JSON.stringify({
        name: addFormData,
      });

      const response = await axios.post(
        `${apiUrl}/nenasala-service/create-nenasala-service`,
        body,
        config
      );

      if (response.data) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
        reject({
            message: 'Unable to create the service. Try Again!',
            status: false,
            statusCode: 400
        });
    }
  });
};

export const editService = async (editFormData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.put(
        `${apiUrl}/nenasala-service/update-nenasala-service-info`,
        editFormData,
        config
      );

      if (response.data.success === true) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    } catch (err) {
        reject({
            message: 'Unable to update the service. Try Again!',
            status: false,
            statusCode: 400
        });
    }
  });
};

export const getAllActiveServices = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
       `${apiUrl}/nenasala-service?status=true`,
        config
      );

      if (response.status === 200) {
        resolve(response.data.data);
      } else {
        reject(response.data.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const getServiceByLimit = async (limit, skip) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/nenasala-service?skip=${skip}&limit=${limit}`,
        config
      );

      if (response.status === 200) {
        resolve(response);
      } 
    } catch (err) {
        reject({
          response: {
            data: {
              message: err.response.data.message,
              status: err.code,
              success: false,
            },
          },
        });
    }
  });
}

export const getServiceBySearch = async (limit, skip, search) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/nenasala-service?skip=${skip}&limit=${limit}&search=${search}`,
        config
      );

      if (response.status === 200) {
        resolve(response);
      } 
    } catch (err) {
        reject({
          response: {
            data: {
              message: err.response.data.message,
              status: err.code,
              success: false,
            },
          },
        });
    }
  });
}

//Service Payments
export const addNewServicePayment = async (addFormData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
  
        const response = await axios.post(
          `${apiUrl}/nenasala-service-payment/create-nenasala-service-payment`,
          addFormData,
          config
        );
  
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      } catch (err) {
          reject({
              status: false,
              statusCode: 400
          });
      }
    });
};

export const getAllServicePayments = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/nenasala-service-payment`,
        config
      );

      if (response.status === 200) {
        resolve(response.data.data);
      } else {
        reject(response.data.data);
      }
    } catch (err) {
        reject(err);
    }
  });
};

export const getServicePaymentsByLimit = async (limit, skip) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/nenasala-service-payment?skip=${skip}&limit=${limit}`,
        config
      );

      if (response.status === 200) {
        resolve(response);
      } 
    } catch (err) {
        reject({
          response: {
            data: {
              message: err.response.data.message,
              status: err.code,
              success: false,
            },
          },
        });
    }
  });
}

export const getAllActiveServicePayments = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
       `${apiUrl}/nenasala-service-payment?status=true`,
        config
      );

      if (response.status === 200) {
        resolve(response.data.data);
      } else {
        reject(response.data.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        reject({
          response: {
            data: {
              message: "Network Error",
              status: err.code,
              statusText: err.code,
            },
          },
        });
      } else {
        reject(err);
      }
    }
  });
};

export const getServicePaymentsBySearch = async (limit, skip, search) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const response = await axios.get(
        `${apiUrl}/nenasala-service-payment?skip=${skip}&limit=${limit}&search=${search}`,
        config
      );

      if (response.status === 200) {
        resolve(response);
      } 
    } catch (err) {
        reject({
          response: {
            data: {
              message: err.response.data.message,
              status: err.code,
              success: false,
            },
          },
        });
    }
  });
}

export const filterServicePayments = async (date, serviceId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };
      let url = `${apiUrl}/nenasala-service-payment?`;

      if (date !== undefined || date !== null) {
        url += `&paid_date=${date}`;
      }

      if (serviceId !== null) {
        url += `&nenasalaServiceId=${serviceId}`;
      }
      const response = await axios.get(url, config);

      if (response.status === 200) {
        resolve(response);
      } 
    } catch (err) {
        reject({
          response: {
            data: {
              message: err.response.data.message,
              status: err.code,
              success: false,
            },
          },
        });
    }
  });
}

export const filterServicePaymentsByDate = async (date) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };
      const response = await axios.get(
        `${apiUrl}/nenasala-service-payment?paid_date=${date}`,
        config
      );
      if (response.status === 200) {
        resolve(response);
      } 
    } catch (err) {
        reject({
          response: {
            data: {
              message: err.response.data.message,
              status: err.code,
              success: false,
            },
          },
        });
    }
  });
}