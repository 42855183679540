import React from 'react';
import '../../assets/css/receipt-styles.css';
import Barcode from 'react-barcode';

function getMonthName(monthNumber) {
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Adjust for zero-based indexing if necessary
  const index = monthNumber - 1;

  // Check for valid month number
  if (index >= 0 && index < 12) {
    return monthNames[index];
  } else {
    return "Invalid month number";
  }
}

const StudentPaymentReceipt = React.forwardRef((props, ref) => {
  const invoiceDate = props.allDetails.invoiceDate;
  const date = new Date(props.allDetails.invoiceDate);

  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  let formattedDate = date.toLocaleDateString('en-US', options);

  formattedDate = formattedDate.replace(',', '');
  const monthName = getMonthName(props.allDetails.month);

  return (
    <>
      <style>
        {`
      @media print {
          @page {
              size: 80mm 100mm;
              margin: 0;
          }
      }
  `}
      </style>
      <div className="payReceipt m-5" ref={ref}>
        <div className='studentReceiptHeader'>
          <h1 style={{ color: 'black' }}>{props.allDetails.subject} {props.allDetails.grade} {props.allDetails.year}</h1>
          <h2 style={{ color: 'black' }}>{props.allDetails.teacherName}</h2>
        </div>
        <div className='studentPayDetails'>
          <h2 style={{ fontSize: 36, color: 'black' }}>Invoice No: {props.allDetails.invoiceNo}</h2>
          <h2 style={{ fontSize: 36, color: 'black' }}>Invoice Date: {formattedDate}</h2>
          <h2 style={{ fontSize: 36, color: 'black' }}>Paid Month: {monthName}</h2>
          <h2 style={{ fontSize: 36, color: 'black' }}>Paid Amount: {Number(props.allDetails.paidAmount).toFixed(2)} {props.allDetails.payType === 'HALF_FREE' ? `(Half Card)` : (
            props.allDetails.payType === 'FULL_FREE' ? `(Free Card)` : null
          )}
          </h2>
        </div>
        <div className='studentReceiptBarcodeSection'>
          <h2 style={{ fontSize: 36 }}>{props.allDetails.studentName}</h2>
          <Barcode value={props.allDetails.barcode} width={3} height={80} className='studentReceiptBarCode' />
        </div>
        <hr style={{ height: 2, color: 'black' }} />
        <div className='studentReceiptInstSection'>
          <h2 style={{ fontSize: 32, color: 'black', fontWeight: 'bold' }}>Nenasala Balangoda</h2>
          <div>
            <p style={{ fontSize: 25, color: 'black', fontWeight: 'bold' }}>No 18/1, Kalthota Road, Balangoda</p>
            <p style={{ fontSize: 25, color: 'black', fontWeight: 'bold' }}>Tele: 0452289889 / 0452286646</p>
            <p style={{ fontSize: 25, color: 'black', fontWeight: 'bold' }}>WhatsApp: 0777775358</p>
          </div>
        </div>
      </div>
    </>
  );
});

export default StudentPaymentReceipt;