import {
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Table,
  Card,
  CardHeader,
  Modal,
  Button,
  Spinner,
  CardBody,
} from "reactstrap";
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import Paginations from "components/Pagination/Paginations";
import { BiEdit } from "react-icons/bi";
import { useParams } from "react-router-dom";
import { filterStudentBySearch } from "actions/Exams";
import { getStudentByClassAssistants } from "actions/ClassAssistantLayout";
import { addExamResults } from "actions/Exams";
import { getExamLeaderBoard } from "actions/Exams";
import { getExamLeaderBoardGraph } from "actions/Exams";
import Chart from "chart.js";
import { Line } from "react-chartjs-2";
import {
  chartOptions,
  parseOptions,
} from "variables/charts.js";


const ExamResults = () => {

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const { teacherClassId, examId, maxMarks } = useParams();

  const [allExamResults, setAllExamResults] = useState(null);
  const [isExams, setIsExams] = useState(false);
  const [isLoadingExams, setLoadingExams] = useState(true);
  const [editModal, setEditmodal] = useState(false);
  const [selectedExam, setSelectedExam] = useState({
    id: null,
    name: "",
    limit: "",
    status: "",
  });
  const [isData, setData] = useState({
    userId: "",
    examId: "",
    mark: "",
  });

  const [isError, setError] = useState({
    mark: "",
    student: "",
  });

  const [studentData, setStudentData] = useState({});

  const [isSuccess, setSuccess] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [isSuccessMessage, setSuccessMessage] = useState("");
  const [isErrorMessage, setErrorMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPge, SetItemsPerPage] = useState(10);
  const [skip, setSkip] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentStart, setCurrentStart] = useState(0);
  const [isDisableInsertBtn, setDisableInsertBtn] = useState(false);
  const [examName, setExamName] = useState('');
  const [isExamName, setIsExamName] = useState(false);
  const [incomeGraphData, setIncomeGraphData] = useState(null);

  const [initialExam, setInitialExam] = useState({
    id: null,
    name: "",
    status: "",
  });

  const leaderBoard = async () => {
    try {
      const response = await getExamLeaderBoardGraph(examId, maxMarks);
      setIncomeGraphData(response.flat());
    } catch (error) {

    }
  }

  const fetchData = async (itemsPerPge, skip) => {
    try {
      await leaderBoard();
      const leaderboard = await getExamLeaderBoard(teacherClassId, examId, skip, itemsPerPge);
      if (leaderboard.data.length > 0) {
        setIsExamName(true);
        // setExamName(leaderboard.data[0].exam.name);
      } else {
        setIsExamName(false);
      }
      setPageCount(leaderboard.data.pageCount);
      setAllExamResults(leaderboard.data);
      setLoadingExams(false);
      setIsExams(true);
    } catch (error) {
      setLoadingExams(false);
      setIsExams(false);
    }
  };

  useEffect(() => {
    fetchData(itemsPerPge, skip);
  }, []);


  const handlePagination = async (pageNumber) => {
    await fetchData(itemsPerPge, pageNumber - 1);
    setCurrentPage(pageNumber);
  }

  const isValidExam = (value) => {
    const errors = {};
    if (!value.mark) {
      errors.mark =
        "Student score is required.";
    }
    if (Object.keys(errors).length > 0) {
      setError(errors);
      return false;
    } else {
      return true;
    }
  };

  const insertExamResults = async () => {
    try {
      if (isValidExam(isData)) {
        setDisableInsertBtn(true);
        isData.mark = +(isData.mark);
        isData.userId = +(isData.userId);
        isData.examId = +(isData.examId);
        const response = await addExamResults(isData);
        if (response.success === true) {
          setSuccessMessage(response.message);
          setSuccess(true);
          setDisableInsertBtn(false);
          setData({
            mark: ""
          });
          setStudentData({})
          fetchData(itemsPerPge, skip);
        } else {
          setErrorMessage(response.message);
          setFailed(true);
          setDisableInsertBtn(false);
          setData({
            marks: ''
          });
        }
      }
    } catch (error) {
      setErrorMessage(error.response.data.message);
      setFailed(true);
      setDisableInsertBtn(false);
    }
  };

  const [receivedData, setReceivedData] = useState(false);

  const getStudentBySeacrh = async (student) => {
    try {
      const response = await getStudentByClassAssistants(student);
      if (response.success === true) {
        setFilteredUsers(null);
        setSearchItem('')
        setReceivedData(true);
        setStudentData(response.students);
        setData({ ...isData, userId: response.students.id, examId: examId })
      } if (response.success === false) {
        setData({ userId: '', examId: '' });
      }
    } catch (error) {
      setData({ userId: '', examId: '' });
    }
  }

  const [searchItem, setSearchItem] = useState('')
  const [filteredUsers, setFilteredUsers] = useState(null)

  const handleInputChange1 = async (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);
    if (searchTerm.length >= 3) {
      const response = await filterStudentBySearch(teacherClassId, searchTerm);
      const filteredItems = response.data.filter((user) =>
        user.user.full_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredUsers(filteredItems);
    }
  }

  const dataValues = incomeGraphData?.map(item => parseInt(item.count, 10));

  const labels = incomeGraphData?.map(item => {
    return (item.mark);
  });

  const lineGraphData = {
    labels: labels,
    datasets: [{
      label: 'Count',
      data: dataValues,
      fill: false,
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1
    }]
  };
  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        <Modal
          className="modal-dialog-centered modal-success"
          isOpen={isSuccess}
          toggle={() => setSuccess(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isSuccessMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setSuccess(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-danger"
          isOpen={isFailed}
          toggle={() => setFailed(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isErrorMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setFailed(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Card className="mb-4 shadow">
          <CardHeader className="border-0">
            <Row>
              <Col md="6" xs="12">
                <span>
                  <h3 className="mb-0">Add New Result</h3>
                </span>
              </Col>
            </Row>
          </CardHeader>
          <Form className="ml-4 mb-4 mr-4">
            <Row>
              <Col sm="6">
                <FormGroup>
                  <Input
                    type="text"
                    value={searchItem}
                    onChange={handleInputChange1}
                    placeholder="Search..."
                    style={{ borderBottomLeftRadius: searchItem !== '' ? '0px' : '', borderBottomRightRadius: searchItem !== '' ? '0px' : '' }}
                  />
                  {searchItem && (
                    <ul style={{ position: 'absolute', width: '94%', listStyleType: 'none', padding: '0', border: '1px solid #ddd', zIndex: 10, backgroundColor: 'white', display: filteredUsers === null ? 'none' : '' }}>
                      {filteredUsers?.map((option, index) => (
                        <li key={index} style={{ padding: '8px', borderBottom: '1px solid #ddd', cursor: 'pointer' }} onClick={() => getStudentBySeacrh(option.user.barcode)}>
                          {option.user.full_name}
                        </li>
                      ))}
                    </ul>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    id="exam"
                    placeholder="Student Name"
                    type="text"
                    value={studentData.full_name || ''}
                    disabled
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    id="limit"
                    placeholder="Student Score"
                    type="text"
                    name="limit"
                    value={isData.mark || ''}
                    required
                    pattern="\d*"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        setData({ ...isData, mark: e.target.value });
                        setError({ ...isError, mark: '' });
                      }
                    }
                    }
                  />
                  {isError.mark && (
                    <p className="text-danger">{isError.mark}</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Button
                  color="primary"
                  type="button"
                  onClick={() => insertExamResults()}
                  disabled={isDisableInsertBtn || !studentData.full_name}
                >
                  Add Result
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
        {isLoadingExams ? (
          <Card>
            <Spinner className="m-10" color="primary" size="sm">Loading...</Spinner>
          </Card>
        ) : !isExams ? (
          <Card className="text-center" style={{ padding: "1rem" }}>
            <img src={require("../../../assets/img/brand/nodata.png")} className="noDataImage" />
          </Card>
        ) : (
          <Card className="shadow" style={{ padding: "2rem" }}>
            <Row>
              <Col>
                <h3 className="mb-3">{isExamName ? `${examName} Results` : null}</h3>
              </Col>
            </Row>
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Barcode</th>
                  <th scope="col">Marks</th>
                  <th scope="col">Position</th>
                </tr>
              </thead>
              <tbody>
                {allExamResults?.map((exam, index) => (
                  <tr key={index}
                  style={{
                    backgroundColor:
                      exam.position === '1' ? '#d4af37' :
                      exam.position === '2' ? '#c0c0c0' :
                      exam.position === '3' ? '#cd7f32' : 'transparent',
                      color: 'black'
                  }}>
                    <td>{exam.full_name || ''}</td>
                    <td>{exam.barcode || ''}</td>
                    <td>{exam.mark || ''}</td>
                    <td>{exam.position || ''}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div style={{ paddingTop: "10px" }}>
              {pageCount > 1 ?
                <Paginations totalPages={pageCount} handlePagination={handlePagination} currentPage={currentPage}
                  currentStart={currentStart}
                  setCurrentStart={setCurrentStart}></Paginations>
                : null}
            </div>
          </Card>
        )}
         <Card className="bg-gradient-default shadow mt-4">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h2 className="text-white mb-0">LeaderBoard Graph</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Line
                    data={lineGraphData}
                    options={{
                      responsive: true,
                      scales: {
                        yAxes: [{
                          ticks: {
                            beginAtZero: true,
                            min: 0,
                            stepSize: 20000,
                          }
                        }]
                      }
                    }}
                  />
                </div>
              </CardBody>
            </Card>
      </div>
    </>
  );
};

export default ExamResults;
